import { Table, TableCell , TableRow , TableHead, TableBody, Button} from "@tremor/react";
import { MinusCircleIcon } from "@heroicons/react/24/outline";
import {convertToCurrency,formatDate} from "../../lib/utils";
import { useAuth } from "../../auth/AuthContext";
import React from "react";

const ApprovedCorrections = ({approvedCorrections,removeCorrection}) => {
    const {user} = useAuth();
   
    if(!approvedCorrections || approvedCorrections.length === 0){
        return null;
    }


    return (
        <>
            <Table className="mt-6 mb-6">
                <TableHead>
                    <TableRow>
                        <TableCell className="text-xs font-bold">NotaNr</TableCell>
                        <TableCell className="text-xs font-bold">BilNr</TableCell>
                        <TableCell className="text-xs font-bold">Datum</TableCell>
                        <TableCell className="text-xs font-bold">Kundkod</TableCell>
                        <TableCell className="text-xs font-bold">Kvitto Total</TableCell>
                        <TableCell className="text-xs font-bold">Korrigerat värde</TableCell>
                        <TableCell className="text-xs font-bold">Skillnad</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        approvedCorrections.map((c,idx)=>(
                            <TableRow key={idx}>
                                <TableCell className="text-xs">{c.KvittoNr} - {c.KvittoDel}</TableCell>
                                <TableCell className="text-xs">{c.CarNr}</TableCell>
                                <TableCell className="text-xs">{formatDate(c.Date)}</TableCell>
                                <TableCell className="text-xs">{c.Kundkod}</TableCell>
                                <TableCell className="text-xs">{convertToCurrency(c.OriginalValue)}</TableCell>
                                <TableCell className="text-xs">{convertToCurrency(c.NewValue)}</TableCell>
                                <TableCell className="text-xs">{convertToCurrency(c.OriginalValue-c.NewValue)}</TableCell>
                                <TableCell className="text-xs">
                                    { (user.rights === 5) ? null :  (<Button onClick={()=>removeCorrection(c.Id)} icon={MinusCircleIcon} variant="light" color="red" className="ml-1"></Button>)}
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </>
    );
}

export default ApprovedCorrections;
