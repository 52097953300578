import React from "react";
import { Card, Button, Badge, Grid, Col, Text, Title } from "@tremor/react";
import { useAuth } from "../../auth/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import {
  getReportType,
  parseDecimalToToTime,
  convertToCurrency,
  minutesToTimeStamp,
} from "../../lib/utils";
import {
  CalendarIcon,
  ClockIcon,
  DocumentTextIcon,
  TruckIcon,
  TagIcon,
  BanknotesIcon,
  UserCircleIcon,
  CalendarDaysIcon,
  ChatBubbleBottomCenterIcon,
  LockClosedIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {holidays} from "../../lib/utils";

const SalaryDetails = ({
  data,
  onToggle,
  lockPasses,
  inspectShift,
  isLockingPasses,
  addCustomShift,
  driverNo,
}) => {
  const { user } = useAuth();

  if (!data) return null;

  const hasReport = (item) => {
    return (
      item.errorReportAdm === "" &&
      item.errorReportVehicle === "" &&
      item.admin_comment !== null &&
      item.admin_comment !== ""
    );
  };

  const getNotesBadges = (item) => {
    let badges = [];

    if (item.errorReportAdm !== "" && item.errorReportAdm !== null) {
      badges.push(
        <Badge
          icon={DocumentTextIcon}
          color="yellow"
          size="xs"
          className="w-[20px] pl-4 mr-1"
        ></Badge>,
      );
    }
    if (item.errorReportVehicle !== "" && item.errorReportVehicle !== null) {
      badges.push(
        <Badge
          icon={TruckIcon}
          color="red"
          size="xs"
          className="w-[20px] pl-4 mr-1"
        ></Badge>,
      );
    }
    if (item.admin_comment !== "" && item.admin_comment !== null) {
      badges.push(
        <Badge
          icon={ChatBubbleBottomCenterIcon}
          color="blue"
          size="xs"
          className="w-[20px] pl-4 mr-1"
        ></Badge>,
      );
    }
    return badges;
  };

  return (
    <div className="flex flex-col pb-12">
      <Title className="">Redovisade pass</Title>
      {user.rights === 2 || user.rights === 1 ? (
        <div className="flex flex-row justify-between items-center">
          <div className="mt-4 mb-4">
            <Button
              icon={LockClosedIcon}
              size="xs"
              onClick={() => lockPasses()}
            >
              {isLockingPasses ? (
                <PulseLoader color="#36d7b7" size={3} />
              ) : (
                "Lås alla pass"
              )}
            </Button>
          </div>
          <div className=" mt-4 mb-4">
            <Button
              icon={PlusIcon}
              color={"green-400"}
              size="xs"
              onClick={() => addCustomShift(driverNo)}
            >
              {isLockingPasses ? (
                <PulseLoader color="#36d7b7" size={3} />
              ) : (
                "Lägg till pass"
              )}
            </Button>
          </div>
        </div>
      ) : null}
      <Grid numItems={1} className="gap-2">
        {data.map((line, idx) => {
          return line.map((item, index) => {
            if (item.id === "") return null;
            return (
              <Col numColSpan={1} key={index+"--"+idx}>
                <Card key={idx + "-" + index} className="flex flex-col">
                  <Grid
                    numItems={9}
                    numItemsSm={6}
                    numItemsLg={9}
                    numItemsMd={6}
                    className="gap-4"
                  >
                    <Col numColSpan={3} numColSpanSm={2} numColSpanMd={1}>
                      <div className="flex flex-col justify-between items-center w-[20px] ml-4">
                        {user.rights === 2 || user.rights === 1 ? (
                          item.locked !== undefined || item.locked !== null ? (
                            <input
                              type="checkbox"
                              checked={item.locked}
                              onChange={() => onToggle(item.id)}
                            />
                          ) : null
                        ) : null}
                        <Badge
                          className="mt-4"
                          color={item.locked ? "green" : "yellow"}
                          size="xs"
                        >
                          <span className="text-xs">
                            {item.locked ? "Godkänd" : "Vänta"}
                          </span>
                        </Badge>
                      </div>
                    </Col>
                    <Col numColSpan={3} numColSpanSm={2} numColSpanMd={2}>
                      <div className="flex flex-row items-center">
                        <CalendarIcon className="h-4 w-4 mr-3 text-stone-600 hover:stroke-blue-500"></CalendarIcon>
                        <Text className="">{item.datestring}</Text>
                      </div>
                      <div className="flex flex-row items-center">
                        <ClockIcon className="h-4 w-4 mr-3 text-stone-600 hover:stroke-blue-500"></ClockIcon>
                        <Text className="">{item.time}</Text>
                      </div>
                      <div className="flex flex-row items-center">
                        <CalendarDaysIcon className="h-4 w-4 mr-3 text-stone-600 hover:stroke-blue-500"></CalendarDaysIcon>
                        <Text
                          className={
                            "font-bold " + 
                            (item.day === "Lördag" || !item.day || holidays.includes(item.datestring)
                              ? "text-rose-400"
                              : "text-slate-500")
                          }
                        >
                          {item.day ? item.day : "Söndag"}
                        </Text>
                      </div>
                    </Col>

                    {(!item.taximeter) ? (
                      // Manual Passes
                      <>
                        <Col numColSpan={1} numColSpanSm={1} numColSpanMd={1}>
                          <div className="flex flex-row items-center">
                            <TagIcon className="h-4 w-4 mr-3 text-stone-600 hover:stroke-blue-500"></TagIcon>
                            <Text className="cursor-pointer underline text-blue-400">
                              <span onClick={() => inspectShift(item.id)}>
                                {getReportType(item.report_type)}{" "}
                              </span>
                            </Text>
                          </div>
                        </Col>
                        <Col numColSpan={3} numColSpanSm={2} numColSpanMd={1}>
                          <div className="flex flex-row items-center">
                            <BanknotesIcon className="h-4 w-4 mr-3 text-stone-600 hover:stroke-blue-500"></BanknotesIcon>
                            <Text className="">
                              {convertToCurrency(item.totMoneyMadeInkTax)}
                            </Text>
                          </div>
                          <div className="flex flex-row items-center">
                            <UserCircleIcon className="h-4 w-4 mr-3 text-stone-600 hover:stroke-blue-500"></UserCircleIcon>
                            <Text>{item.totLoggedInTime}</Text>
                          </div>
                        </Col>
                        <Col numColSpan={1} numColSpanSm={1} numColSpanMd={1}>
                          <div className="flex flex-row items-center">
                            {item.ob1 &&
                            item.ob1 !== 0 &&
                            item.ob1 !== "" &&
                            item.ob1 > 0 ? (
                              <>
                                <Text className="text-sm mr-2">OB1:</Text>
                                <Text className="text-slate-500">
                                  {item.ob1 > 0
                                    ? parseDecimalToToTime(item.ob1)
                                    : null}
                                </Text>
                              </>
                            ) : null}
                          </div>
                          <div className="flex flex-row items-center">
                            {item.ob2 &&
                            item.ob2 !== 0 &&
                            item.ob2 !== "" &&
                            item.ob2 > 0 ? (
                              <>
                                <Text className="text-sm mr-2">OB2:</Text>
                                <Text className="text-slate-500">
                                  {item.ob2 > 0
                                    ? parseDecimalToToTime(item.ob2)
                                    : null}
                                </Text>
                              </>
                            ) : null}
                          </div>
                        </Col>
                      </>
                    ) : (
                      // Auto Passes
                      <>
                        <Col numColSpan={3} numColSpanSm={1} numColSpanMd={1}>
                          <div className="flex flex-row items-center">
                            <TruckIcon className="h-4 w-4 mr-3 text-stone-600 hover:stroke-blue-500"></TruckIcon>
                            <Text className="">{item.carNo}</Text>
                          </div>
                          <div className="flex flex-row items-center">
                            <DocumentTextIcon className="h-4 w-4 mr-3 text-stone-600 hover:stroke-blue-500"></DocumentTextIcon>
                            <Text className="cursor-pointer underline text-blue-400">
                              <div className="flex flex-row">
                                <span onClick={() => inspectShift(item.id)}>
                                  {item.shiftNo}{" "}
                                </span>
                                <div
                                  className={`w-3 h-3 rounded ml-2 mt-1 ${item.unregisteredShiftId ? "bg-yellow-300" : "bg-green-300"}`}
                                ></div>
                              </div>
                            </Text>
                          </div>
                        </Col>
                        <Col numColSpan={3} numColSpanSm={2} numColSpanMd={1}>
                          <div className="flex flex-row items-center">
                            <BanknotesIcon className="h-4 w-4 mr-3 text-stone-600 hover:stroke-blue-500"></BanknotesIcon>
                            <Text className="">
                              {convertToCurrency(item.totMoneyMadeInkTax)}
                            </Text>
                          </div>
                          <div className="flex flex-row items-center">
                            <UserCircleIcon className="h-4 w-4 mr-3 text-stone-600 hover:stroke-blue-500"></UserCircleIcon>
                            <Text>{item.totLoggedInTime}</Text>
                          </div>
                        </Col>
                        <Col numColSpan={3} numColSpanSm={1} numColSpanMd={1}>
                          <div className="flex flex-row items-center">
                            {item.ob1 &&
                            item.ob1 !== 0 &&
                            item.ob1 !== "" &&
                            item.ob1 > 0 ? (
                              <>
                                <Text className="text-sm mr-2">OB1:</Text>
                                <Text className="text-slate-500">
                                  {item.ob1 > 0
                                    ? parseDecimalToToTime(item.ob1)
                                    : null}
                                </Text>
                              </>
                            ) : null}
                          </div>
                          <div className="flex flex-row items-center">
                            {item.ob2 &&
                            item.ob2 !== 0 &&
                            item.ob2 !== "" &&
                            item.ob2 > 0 ? (
                              <>
                                <Text className="text-sm mr-2">OB2:</Text>
                                <Text className="text-slate-500">
                                  {item.ob2 > 0
                                    ? parseDecimalToToTime(item.ob2)
                                    : null}
                                </Text>
                              </>
                            ) : null}
                          </div>
                        </Col>
                      </>
                    )}
                    <Col numColSpan={3} numColSpanSm={1} numColSpanMd={1}>
                      <div className="flex flex-row items-center">
                        <Text className="text-sm mr-2 text-gray-500">
                          Arb Tid:
                        </Text>
                        <Text className="text-gray-500">
                          {item.hoursWorked}
                        </Text>
                      </div>
                      <div className="flex flex-row items-center">
                        <Text className="text-sm mr-2 text-gray-500">
                          Tot rast:
                        </Text>
                        <Text className="text-gray-500">
                          {minutesToTimeStamp(
                            item.breakTime + item.extraBreakTime,
                          )}
                        </Text>
                      </div>
                    </Col>
                    {!item.driverReported ? (
                      <Col numColSpan={3} numColSpanSm={2} numColSpanMd={1}>
                        <div className="flex flex-row items-center">
                          <Text className="text-sm mr-2 text-gray-500 ">
                            Bom:
                          </Text>
                          <Text className="text-gray-500">
                            {convertToCurrency(item.moneyBom)}
                          </Text>
                        </div>
                        <div className="flex flex-row items-center">
                          <Text className="text-sm mr-2 text-gray-500">
                            Kontant:
                          </Text>
                          <Text className="text-gray-500">
                            {convertToCurrency(item.cash)}
                          </Text>
                        </div>
                        {getNotesBadges(item)}
                      </Col>
                    ) : null}
                    <Col numColSpan={3} numColSpanSm={2} numColSpanMd={1}>
                      <div className="flex flex-row items-center">
                        <Text className="text-sm mr-2 text-gray-500">
                          kr/mil:
                        </Text>
                        <Text className="text-gray-500">
                          {convertToCurrency(item.revenuePerMile)}
                        </Text>
                      </div>
                      <div className="flex flex-row items-center">
                        <Text className="text-sm mr-2 text-gray-500">
                          kr/tim:
                        </Text>
                        <Text className="text-gray-500">
                          {convertToCurrency(item.revenuePerHour)}
                        </Text>
                      </div>
                    </Col>
                  </Grid>
                </Card>
              </Col>
            );
          });
        })}
      </Grid>
    </div>
  );
};

export default SalaryDetails;
