import React from 'react'
import { Title, Button, Textarea ,Text } from '@tremor/react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { useAuth } from '../../auth/AuthContext'

const SalaryApprove = ({comment,setComment,approvedSalary = {},approveSalary,setAdminComment,adminComment,date}) => {
    
    const {user} = useAuth();
    const {approvedByAdmin,approvedByDriver,completed_userID} = approvedSalary;
    
    const isDisabled = () => {
        if(user.rights === 5){
            let sameMonth = false;
            // Check if date is the current month
            if(date){
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth();
                const currentYear = currentDate.getFullYear();

                const salaryDate = new Date(date);
                const salaryMonth = salaryDate.getMonth();
                const salaryYear = salaryDate.getFullYear();
                
                if(currentMonth === salaryMonth && currentYear === salaryYear){
                    sameMonth = true;
                }
            }

            if(sameMonth){
                return true; // should be disalbed
            }else{
                return approvedByDriver;
            }

        }
    }


    const getApproveButton = () => {
        if(completed_userID){
            return null;
        }
        
        if(user.rights === 5){
            return (
                <Button disabled={isDisabled()} className="mt-4 float-right" onClick={() => approveSalary()}>Godkänn</Button>
            )
        }else if((user.rights === 2 || user.rights === 1 ) && approvedByDriver){
            return (
                <Button disabled={approvedByAdmin} className="mt-4 float-right" onClick={() => approveSalary()}>Godkänn</Button>
            )
        }else if(user.rights === 6){
            return (
                <Button disabled={!approvedByDriver || !approvedByAdmin } className="mt-4 float-right" onClick={() => approveSalary()}>Godkänn</Button>
            )
        }
    }

    return (
        <div className="mt-6">
            <>
                <label>Förarens kommentar</label>
                {
                    (user.rights === 5) ? 
                    <Textarea className="mt-2" onValueChange={setComment} defaultValue={comment} disabled={approvedByDriver}/>
                    : <Text className="mt-2">{(comment.length === 0 ) ? 'Ingen kommentar' : comment }</Text> 
                }
                
            </>
            {
                (user.rights !== 5 && approvedByDriver) ? (
                    <div className="mt-6">
                        <label>Admin kommentar</label>
                        {
                            ( (user.rights === 2 || user.rights === 1 ) && !approvedByAdmin) ?
                                <Textarea className="mt-2" onValueChange={setAdminComment} defaultValue={adminComment} />
                            : <Text className="mt-2">{(adminComment.length === 0 ) ? 'Ingen kommentar' : adminComment }</Text> 
                        }
                    </div>
                ) : null 
            }
            <Title className="mt-6">Godkannande</Title>
            
            <div className="">
                <div className="flex flex-row mt-2 items-center">
                    { (approvedByDriver) ? <CheckCircleIcon className={`h-6 w-6 mr-2 text-green-500 `} /> : <XCircleIcon className={`h-6 w-6 mr-2 text-yellow-500 `} /> } 
                    <Text>Godkänd av Förare</Text>
                </div>
                <div className="flex flex-row mt-2 items-center">
                    { (approvedByAdmin) ? <CheckCircleIcon className={`h-6 w-6 mr-2 text-green-500 `} /> : <XCircleIcon className={`h-6 w-6 mr-2 text-yellow-500 `} /> } 
                    <Text>Godkänd av Administrator - {}</Text>
                </div>   
            </div>
            <div className="mb-6 h-12">
                {
                    getApproveButton()
                }
            </div>
        </div>
    )
}

export default SalaryApprove