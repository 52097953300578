import React, { useEffect , useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { 
    TextInput,
    Card,
    Button,
    Text,
    Title,
    Select, SelectItem
} from "@tremor/react";

import  toast from 'react-hot-toast';
import { CurrencyDollarIcon, UserPlusIcon , UserGroupIcon } from '@heroicons/react/24/outline';

import BreadCrumbNav from '../components/BreadCrumbNav';
import AdminSection from '../components/User/AdminSection';
import EkonomiSection from '../components/User/EkonomiSection';
import VaxelPersonalSection from '../components/User/VaxelPersonalSection';

import axios from 'axios';

const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

const SignUp = () => {
    const {isAuthenticated,user} = useAuth()
    const navigate = useNavigate()
    const [newUser, setNewUser] = useState({});
    const [companySelection, setCompanySelection] = useState({});


    useEffect(() => {
        if(!user || !isAuthenticated){
            navigate('/signin')
            return;
        }
    }   , [isAuthenticated,navigate,user])
    
    /**
     * Handle Submit
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try{
            let data = new FormData(event.currentTarget);
            data = Object.assign({},data,newUser)
            const response = await axios.post('/signup',data);
            if(response.status === 200){
                navigate('/app/users')
            }
        }catch(err){
            console.log(err);
            toast.error(err.response.data.message) 
        }
    }

    /**
     * Validate
     */
    const validate = () => {
        const valid = ( (newUser.email === "" || !newUser.email) || (newUser.password === "" || !newUser.password) || (newUser.name === "" || !newUser.name) || (newUser.rights === "" || !newUser.rights))
        return valid;
    }

    const validateEmail = (value) => {
        if(value === "" || !value){
            return false;
        }
        const valid = value.match(regex);
        return valid;
    }


    const onChange = (value,name) => {
        let mutation = {};
        mutation[name] = value;
        
        const updatedUser = Object.assign({}, newUser, mutation);
        setNewUser(updatedUser);
    }

    const onCompanySelection = (id,value) => {
        const mutation = {...companySelection,[id]:value}
        setCompanySelection(mutation)
        onChange(mutation,'companies')
    }

    /**
     * Mount subsection of the form
     */
    const mountSection = () => {
        switch (newUser.rights) {
            case 2:
                return <AdminSection onSectionChange={onChange} 
                            companySelection={companySelection} 
                            onCompanySelection={onCompanySelection} 
                            setCompanySelection={setCompanySelection} />           
            case 6:
                return <EkonomiSection onSectionChange={onChange} 
                            companySelection={companySelection} 
                            onCompanySelection={onCompanySelection} 
                            setCompanySelection={setCompanySelection} />
            case 3:
                return <VaxelPersonalSection 
                            onSectionChange={onChange}
                            companySelection={companySelection} 
                            onCompanySelection={onCompanySelection} 
                            setCompanySelection={setCompanySelection} />
            default:
                return null
        }
    }

    return (
        <div className="container mx-auto max-w-[80%] flex flex-col justify-center items-center">
            <BreadCrumbNav />
            <Card className="mt-12 max-w-screen-md p-8" >
              <Title>Create User</Title>
                <form onSubmit={handleSubmit}>
                    <div className="mt-4 mb-2">
                        <Text className="mb-2">Name</Text>
                        <TextInput name="name" onChange={(e)=>onChange(e.target.value,'name')} placeholder="Jon Svensson" type="text" />
                    </div>
                    <div className="mt-4 mb-2">
                        <Text className="mb-2">Email</Text>
                        <TextInput 
                            name="email" 
                            value={newUser.email || ""}
                            error={!validateEmail(newUser.email)}
                            errorMessage="Email format is wrong!"
                            onChange={(e)=>onChange(e.target.value,'email')} 
                            placeholder="user@email.com" type="text" />
                    </div>
                    <div className="mt-4 mb-2">
                        <Text className="mb-2">Password</Text>
                        <TextInput name="password" onChange={(e)=>onChange(e.target.value,'password')} placeholder="***********" type="password" />
                    </div>
                    <div className="mt-4 mb-2">
                        <Text className="mb-2">Type</Text>
                        <Select value={newUser?.rights || null} onValueChange={(e)=>onChange(e,'rights')}>
                            <SelectItem value={2} icon={UserPlusIcon}>
                                Administrator
                            </SelectItem>
                            <SelectItem value={6} icon={CurrencyDollarIcon}>
                                Ekonomi
                            </SelectItem>
                            <SelectItem value={3} icon={UserGroupIcon}>
                                Växelpersonal
                            </SelectItem>
                        </Select>
                    </div>
                    { mountSection() }
                    <Button disabled={validate()} className="mt-6 w-full" size="xs" type="submit" >Register</Button>
                </form>
            </Card>
        </div>
    )
}

export default SignUp