import axios from "axios";
import React, { useEffect , useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import UsersTable from '../components/User/UsersTable';
import RemoveUserDialog from '../components/User/RemoveUserDialog';

const Users = () => {
    const [users, setUsers] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const {isAuthenticated,user} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/signin')
        }
    }   , [isAuthenticated])



    useEffect(() => {
        if(!user || user.rights === 5){
            navigate('/')
        }

        getUsers();
    },[]);

    const getUsers = async () => {
        try{
            const response = await axios.get('/users');
            console.log(response.data.data.users)  
            setUsers(response.data.data.users);
        }catch(err){
            console.log(err);
        }
    }

    useEffect(() => {
        if(selectedUser){
            setIsModalOpen(true);
        }else{
            setIsModalOpen(false);
        }
    },[selectedUser]);
    

    const removeUser = async (id) => {
        try{
            await axios.delete(`/users/${id}`);
            const response = await axios.get('/users');
            if(response.status === 200){
                setSelectedUser(null);
                getUsers();
            }
        }catch(err){
            console.log(err);
        }
    }


    if(!users)
        return <h5>Loading...</h5>


    return (
        <div className="container mx-auto max-w-[80%]">
            <UsersTable users={users} setSelectedUser={setSelectedUser} />
            <RemoveUserDialog 
                isOpen={isModalOpen} 
                removeUser={removeUser} 
                selectedUser={selectedUser} 
                setIsOpen={setIsModalOpen} 
                setSelectedUser={setSelectedUser}/>
        </div>
    );
}

export default Users;