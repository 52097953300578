import React, { useEffect } from "react";
import {
  TextInput,
  Button,
  Grid,
  Col,
  Textarea,
  Callout,
  Divider,
  Text,
} from "@tremor/react";
import {
  formatTime,
  convertToCurrency,
  calculateTotalHours,
  validateTime,
  formatDate,
} from "../../lib/utils";
import { useAuth } from "../../auth/AuthContext";
import {
  ExclamationTriangleIcon,
  ArrowPathIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import "react-datepicker/dist/react-datepicker.css";
import FileUploader from "../FileUploader";
import ChangeAkareModal from "./ChangeAkareModal";
import dayjs from "dayjs";
import TooltipMarker from "../Tooltip";
import { Tooltip } from "react-tooltip";
import BreaksList from "./BreaksList";
import AddBreak from "./AddBreak";

const FullShiftReport = ({
  shift = {},
  onChange,
  updateShift,
  changeAkare,
  removeAttachment,
  success,
  updateBreaks,
  unlockShift
}) => {
  const { report, breaks, registered } = shift;
  const { user } = useAuth();

  const [initialTotal, setInitialTotal] = React.useState(
    report.totMoneyMadeInkTaxUI,
  );
  const [initialExpenses, setInitialExpenses] = React.useState(report.expenses);
  const [initialStarttime, setInitialStarttime] = React.useState(
    formatTime(report.starttimeUI),
  );
  const [initialEndtime, setInitialEndtime] = React.useState(
    formatTime(report.endtimeUI),
  );
  const [changedTotal, setChangedTotal] = React.useState(false);
  const [expensesChanged, setExpensesChanged] = React.useState(false);
  const [timeChangedStart, setStartTimeChanged] = React.useState(false);
  const [timeChangedEnd, setEndTimeChanged] = React.useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  /**
     * Check if the total amount of money made has changed

     */
  useEffect(() => {
    if (parseInt(report.totMoneyMadeInkTaxUI) !== initialTotal) {
      setChangedTotal(true);
    } else {
      setChangedTotal(false);
    }
    if (parseInt(report.expenses) !== initialExpenses) {
      setExpensesChanged(true);
    } else {
      setExpensesChanged(false);
    }

    if (formatTime(report.starttimeUI) !== initialStarttime) {
      setStartTimeChanged(true);
    } else {
      setStartTimeChanged(false);
    }
    if (formatTime(report.endtimeUI) !== initialEndtime) {
      setEndTimeChanged(true);
    } else {
      setEndTimeChanged(false);
    }
  }, [
    report.totMoneyMadeInkTaxUI,
    report.expenses,
    report.starttimeUI,
    report.endtimeUI,
  ]);

  const isDisabled = () => {
    let isDisabled = false;

    // Validates Time input format
    if (
      !validateTime(formatTime(report.starttimeUI)) ||
      !validateTime(formatTime(report.endtimeUI))
    ) {
      isDisabled = true;
    }

    // Check if the total amount of money made has changed
    if (
      user.rights === 5 &&
      !report.locked &&
      changedTotal &&
      report.errorReportAdm === ""
    ) {
      isDisabled = true;
    }
    // Check if the expenses has changed
    if (
      user.rights === 5 &&
      !report.locked &&
      expensesChanged &&
      report.errorReportVehicle === ""
    ) {
      isDisabled = true;
    }

    // Check if the admin comment is empty
    if (
      user.rights !== 5 &&
      (changedTotal || expensesChanged) &&
      (report.admin_comment === "" || report.admin_comment === null)
    ) {
      isDisabled = true;
    }

    // Check if the start and end time is empty
    if (
      user.rights === 5 &&
      !report.locked &&
      (timeChangedStart || timeChangedEnd) && report.admin_comment === ""
    ) {
      isDisabled = true;
    }

    return isDisabled;
  };

  const removeBreak = (tmpId) => {
    let newBreaks = breaks.filter((b) => b.tmpId !== tmpId);
    updateBreaks(newBreaks);
  };

  const addBreak = (starttime, endtime) => {
    if (!starttime || !endtime) return;
    let newBreaks = [
      ...breaks,
      {
        starttime: starttime,
        endtime: endtime,
        manuallyReported: true,
        tmpId: Date.now(),
      },
    ];
    updateBreaks(newBreaks);
  };


  if (!report) return null;

  return (
    <div className="pb-12">
      <Tooltip id="my-tooltip" />
      <Grid numItems={4} className="gap-2">
        <Col numColSpan={2}>
          <div className="mt-4 mb-4 ">
            <Text>
              Jag har kört fordon:
              <TooltipMarker place="right" contentKey="carNo" />
            </Text>
            {/* <TextInput value={report.carNo} disabled /> */}
            <div className="font-light text-sm">{report.carNo}</div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-4 ">
            <Text>
              Rapport nr:
              <TooltipMarker place="right" contentKey="rapportNo" />
            </Text>
            <div className="font-light text-sm">{report.workshiftNo}</div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-4 ">
            <Text>
              Start Datum:
              <TooltipMarker place="right" contentKey="startdatum" />
            </Text>
            <div className="font-light text-sm">
              {formatDate(report.startdate)}
            </div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-4 ">
            <Text>
              Slut Datum:
              <TooltipMarker place="right" contentKey="slutdatum" />
            </Text>
            <div className="font-light text-sm">
              {formatDate(report.enddate)}
            </div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>
              Start (uppgift från taxameter):
              <TooltipMarker place="right" contentKey="tid" />
            </Text>
            <div className="font-light text-sm">
              {dayjs(report.starttime).utc().format("HH:mm")}
            </div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>
              Slut (uppgift från taxameter):
              <TooltipMarker place="right" contentKey="tid" />
            </Text>
            <div className="font-light text-sm">
              {dayjs(report.endtime).utc().format("HH:mm")}
            </div>
          </div>
        </Col>

        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>Start:</Text>
            <TextInput
              error={!validateTime(formatTime(report.starttimeUI))}
              errorMessage={"Var vänlig att ange tiden i formatet: TT:MM"}
              // defaultValue={ dayjs(report.starttimeUI).utc().format("HH:mm") }
              value={formatTime(report.starttimeUI)}
              disabled={(report.locked || (registered && user.rights === 5))}
              onValueChange={(value) => onChange(value, "starttimeUI")}
            />
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>Slut:</Text>
            <TextInput
              error={!validateTime(formatTime(report.endtimeUI))}
              errorMessage={"Var vänlig att ange tiden i formatet: TT:MM"}
              value={formatTime(report.endtimeUI)}
              disabled={report.locked || (registered && user.rights === 5)}
              onValueChange={(value) => onChange(value, "endtimeUI")}
            />
          </div>
        </Col>

        <Col numColSpan={4}>
          <Divider>Rast</Divider>
        </Col>

        <BreaksList breaks={breaks} removeBreak={removeBreak} />
        <AddBreak addBreak={addBreak} />

        <Col numColSpan={4}>
          <div className="mt-4 mb-6 ">
            <Text>
              Arbetad tid :
              <TooltipMarker place="right" contentKey="timeworked" />
            </Text>
            <div className="font-light text-sm">
              {calculateTotalHours(shift)}
            </div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>Totalt inkört (uppgift från taxameter) :</Text>
            <div className="font-light text-sm">
              {convertToCurrency(report.totMoneyMadeInkTax)}
            </div>
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>
              Totalt inkört :
              <TooltipMarker place="right" contentKey="totaltinkort" />
            </Text>
            <TextInput
              type="number"
              value={report.totMoneyMadeInkTaxUI}
              disabled={(report.locked || registered) && user.rights === 5}
              onValueChange={(value) => onChange(value, "totMoneyMadeInkTaxUI")}
            />
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>
              Redovisas kontant :
              <TooltipMarker place="right" contentKey="redovisas" />
            </Text>
            <div className="font-light text-sm">
              {convertToCurrency(report.totAmCash)}
            </div>
          </div>
        </Col>

        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>
              Utlägg :
              <TooltipMarker place="right" contentKey="utlagg" />
            </Text>
            <TextInput
              type="number"
              defaultValue={report.expenses}
              onValueChange={(value) => onChange(value, "expenses")}
              disabled={(report.locked || registered) && user.rights === 5}
            />
          </div>
        </Col>
        <Col numColSpan={2}>
          <div className="mt-4 mb-6 ">
            <Text>Kvar att redovisa kontant :</Text>
            <div className="font-light text-sm">
              {convertToCurrency(
                parseInt(report.totAmCash) - parseInt(report.expenses),
              )}
            </div>
          </div>
        </Col>

        <Col numColSpan={4}>
          {breaks.length === 0 && calculateTotalHours(shift) > 6 ? (
            <Callout
              className="mt-4 mb-4"
              title="Reasons needed"
              icon={ExclamationTriangleIcon}
              color="yellow"
            >
              Du har jobbat mer än 6 timmar men inte tagit någon rast, ange
              orsak till den uteblivna rasten i felanmälan ekonomi eller fyll i
              den rast du haft i rastfälten.
            </Callout>
          ) : null}
          {user.rights === 5 &&
          !report.locked &&
          (changedTotal || timeChangedStart || timeChangedEnd) &&
          report.errorReportAdm === "" ? (
            <Callout
              className="mt-4 mb-4"
              title="Förklara varför du har ändrat värdena"
              icon={ExclamationTriangleIcon}
              color="rose"
            >
              Du har ändrat värden. Berätta varför
              nedanför under felanmälan ekonomi.
            </Callout>
          ) : null}
          {user.rights === 5 &&
          !report.locked &&
          expensesChanged &&
          report.errorReportVehicle === "" ? (
            <Callout
              className="mt-4 mb-4"
              title="Förklara varför du har ändrat värdena"
              icon={ExclamationTriangleIcon}
              color="yellow"
            >
              Du har redovisat att du gjort utlägg under passet. Bifoga en bild
              på kvittot under felanmälan ekonomi.
            </Callout>
          ) : null}
          {user.rights !== 5 &&
          (changedTotal || expensesChanged) &&
          (report.admin_comment === "" || report.admin_comment === null) ? (
            <Callout
              className="mt-4 mb-4"
              title="Förklara varför du har ändrat värdena"
              icon={ExclamationTriangleIcon}
              color="orange"
            >
              Du har ändrat några värden. Ange en förklaring i avsnittet.
            </Callout>
          ) : null}
        </Col>
        <Col numColSpan={4}>
          <Text className="">Avvikelser / Felrapportering</Text>
          <div className="mt-4 mb-6">
            <Text>Ekonomi/Lön/ADM :</Text>
            <Textarea
              value={report.errorReportAdm || ""}
              onChange={(e) => onChange(e.target.value, "errorReportAdm")}
              disabled={
                report.locked ||
                (registered && user.rights === 5) ||
                user.rights === 2 ||
                user.rights === 1
              }
            />
          </div>
          {report.locked ||
          (registered && user.rights === 5) ||
          user.rights === 2 ||
          user.rights === 1 ? null : (
            <FileUploader type="adm" id={report.ID} success={success} />
          )}
          {report.errorImgAdm ? (
            <>
              <a
                download
                href={
                  `${process.env.REACT_APP_BASE_URL_API}/uploads/` +
                  report.errorImgAdm
                }
              >
                <img
                  alt=""
                  src={
                    `${process.env.REACT_APP_BASE_URL_API}/uploads/` +
                    report.errorImgAdm
                  }
                  className="w-[92px] border "
                />
              </a>
              <Button
                size="xs"
                icon={TrashIcon}
                color="red-400"
                className="mt-4"
                disabled={
                  report.locked ||
                  (registered && user.rights === 5) ||
                  user.rights === 2 ||
                  user.rights === 1
                }
                onClick={() => removeAttachment("adm", report.ID)}
              >
                Ta bort
              </Button>
            </>
          ) : null}
        </Col>

        <Col numColSpan={4}>
          <Text>Felanmälan</Text>
          <div className="mt-4 mb-6">
            <Text>Fordon / Teknik / Tvätthall :</Text>
            <Textarea
              className="mb-6"
              value={report.errorReportVehicle || ""}
              onChange={(e) => onChange(e.target.value, "errorReportVehicle")}
              disabled={
                report.locked ||
                (registered && user.rights === 5) ||
                user.rights === 2 ||
                user.rights === 1
              }
            />
            {report.locked ||
            (registered && user.rights === 5) ||
            user.rights === 2 ||
            user.rights === 1 ? null : (
              <FileUploader type="vehicle" id={report.ID} success={success} />
            )}
            {report.errorImgVehicle ? (
              <>
                <img
                  alt=""
                  src={
                    `http://localhost:3005/api/uploads/` +
                    report.errorImgVehicle
                  }
                  className="w-[92px] border "
                />
                <Button
                  disabled={
                    report.locked ||
                    (registered && user.rights === 5) ||
                    user.rights === 2 ||
                    user.rights === 1
                  }
                  className="mt-2"
                  onClick={() => removeAttachment("vehicle", report.ID)}
                >
                  Ta bort
                </Button>
              </>
            ) : null}
          </div>
        </Col>
        {user.rights === 2 || user.rights === 1 ? (
          <Col numColSpan={4}>
            <Text>Admin Notes</Text>
            <div className="mt-4 mb-6">
              <Text>Admin comment :</Text>
              <Textarea
                className="mb-6"
                value={report.admin_comment || ""}
                onChange={(e) => onChange(e.target.value, "admin_comment")}
              />
            </div>
          </Col>
        ) : null}

        <Col numColSpan={4}>
          <div className="flex flex-row justify-end">
            {(report.locked || registered) && user.rights === 5 ? null : (
              <>
                {user.rights === 2 || user.rights === 1 ? (
                  <Button
                    className="mr-6 cursor-pointer"
                    icon={ArrowPathIcon}
                    size="xs"
                    variant="light"
                    color="orange"
                    onClick={() => setIsModalOpen(!isModalOpen)}
                  >
                    Bytta Åkare
                  </Button>
                ) : null}
                {((user.rights === 2 || user.rights === 1) && registered) ? <Button variant="secondary" className="ml-2 mr-2" onClick={()=>unlockShift(report.ID)}>Unlock Shift</Button> : null }
                <Button onClick={updateShift} disabled={isDisabled()}>
                  {user.rights === 2 || user.rights === 1
                    ? "Godkänn"
                    : "Godkänn"}
                </Button>

              </>
            )}
          </div>
        </Col>
      </Grid>
      {isModalOpen ? (
        <ChangeAkareModal
          success={(akare) => {
            setIsModalOpen(false);
            changeAkare(akare);
          }}
          failure={() => setIsModalOpen(false)}
        />
      ) : null}
    </div>
  );
};

export default FullShiftReport;
