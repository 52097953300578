import React from "react";
import { SearchSelectItem,SearchSelect, Text , Grid, Col, DatePicker} from '@tremor/react';
import {useAuth} from "../../auth/AuthContext";
import dayjs from "dayjs";

const DashboardHeader = ({setCompany,setDriver,setStartDate, setEndDate, company,driver,drivers,startdate,enddate,companies,car,setCar,cars,setSelected,overall}) => {

    const {user} = useAuth();

    const onChangeDate = (value,action) => {
        const date = dayjs(value).format('YYYY-MM-DD')
        if(action === 'startdate'){
            setStartDate(date)
        }else{
            setEndDate(date)
        }
    }

    return (
        <div className="">
            <Grid numItems={3} numItemsLg={3} numItemsMd={3} numItemsSm={3}>
                <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1} >
                    <div className="mt-4 mb-2  mr-6">
                        <Text className="mb-2">Från:</Text>
                        <DatePicker value={ new Date(startdate) } displayFormat="yyyy-MM-dd" className=" mx-auto" onValueChange={(v)=>onChangeDate(v,'startdate')} />
                    </div>
                    <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                    Välj startdatum
                    </p>
                </Col>
                <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1} >
                    <div className="mt-4 mb-2 mr-6">
                        <Text className="mb-2">Till:</Text>
                        <DatePicker value={ new Date(enddate) } displayFormat="yyyy-MM-dd" className=" mx-auto" onValueChange={(v)=>onChangeDate(v,'enddate')} />
                    </div>
                    <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                    Välj slutdatum
                    </p>
                </Col>
                <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1}>
                    <div className="mt-4 mb-2 mr-6">
                        <Text className="mb-2">Företag</Text>
                        <SearchSelect value={company || ""} onValueChange={(value)=>setCompany(value)}>
                            {
                                companies.map((company,idx) => (
                                    <SearchSelectItem key={idx} value={company.akareNo}>
                                        {company.haldaName}
                                    </SearchSelectItem>
                                ))
                            }
                        </SearchSelect>
                    </div>
                    <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                        Välj Företag
                    </p>
                </Col>
                {
                    (user.rights === 5) ? null :(
                        <Col numColSpan={3} numColSpanMd={1}  numColSpanSm={3} numColSpanLg={1}>
                            <div className="mt-4 mb-2  mr-6">
                                <Text className="mb-2">Förare</Text>
                                <SearchSelect disabled={overall} value={driver || ""} onValueChange={(value)=>{setCar(null);setDriver(value);setSelected(value)}}>
                                    {
                                        drivers.map((driver,idx) => (
                                            <SearchSelectItem key={idx} value={driver.driverNo}>
                                                {driver.name} - {driver.driverNo}
                                            </SearchSelectItem>
                                        ))
                                    }
                                </SearchSelect>
                            </div>
                            <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                                Välj Förare
                            </p>
                        </Col>
                    ) 
                }
                {
                    (user.rights === 5) ? null :(
                        <Col numColSpan={3}  numColSpanMd={1} numColSpanSm={3} numColSpanLg={1}>
                            <div className="mt-4 mb-2  mr-6">
                                <Text className="mb-2">Bil</Text>
                                <SearchSelect disabled={overall} value={car || ""} onValueChange={(value)=>{setDriver(null);setCar(value);setSelected(value)}}>
                                    {
                                        cars.map((car,idx) => (
                                            <SearchSelectItem key={idx} value={car.carNo}>
                                                {car.carNo}
                                            </SearchSelectItem>
                                        ))
                                    }
                                </SearchSelect>
                            </div>
                            <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                                Välj bil
                            </p>
                        </Col>
                    ) 
                }
            </Grid>
        </div>
    );
}

export default DashboardHeader;