import React from 'react'
import { TextInput , Button} from '@tremor/react'

const RegisterMoney = ({amount, setAmount,registerMoney , disabled}) => {
    return (
        <div className="mt-4 mb-2 w-1/5 mr-6">
            <TextInput type="number" name="amount" placeholder="Amount for money" value={amount} onValueChange={setAmount} />
            <Button className="mt-4" disabled={disabled} size="xs" onClick={registerMoney}>Registrera pengar</Button>
        </div>
    )
}

export default RegisterMoney
