import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Callout } from "@tremor/react";
import React from "react";

const SalaryStatus = ({approvedByDriver,approvedByAdmin,completed_userID}) => {

    const getMessage = () => {
        if(completed_userID){
            return "Status: Färdighanterad.";
        }

       
        if(approvedByDriver && !approvedByAdmin){
            return "Status: Väntar på godkännande från admin.";
        }else if(!approvedByDriver && approvedByAdmin === 0){
            return "Status: Väntar på godkännande från förare.";
        }else if (approvedByDriver && approvedByAdmin === 1){
            return "Status: Inväntar hantering av Löneadministratör";
        }else {
            return "Status: Väntar på godkännande.";
        }
    }

    return (
        <Callout
            className="w-full mt-4"
            title="Lönestatus"
            icon={InformationCircleIcon}
            color={ (completed_userID) ? "green" : "yellow"}
        >
            {getMessage()}
        </Callout>
        );
}

export default SalaryStatus;
