import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../config/routes';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    if(!user || !isAuthenticated){
        navigate('/signin')
        return;
    }

}, [isAuthenticated,navigate,user])

    const signOut = async () => {
      try{
        await axios.get('/signout');
        setIsAuthenticated(false);
        setUser(null);
        setWaitAuthCheck(false);
        navigate('/signin')
      }catch(e){
        console.log(e)
        //Not possible, show error
      }
    }


    const signIn = (user) => {
      setUser(user);
      setIsAuthenticated(true);
      setWaitAuthCheck(false);
      navigate(APP_ROUTES.SHIFTS)
    }



  useEffect(() => {
      async function handleAuthentication(){
        try{
          const response = await axios.get('users/me');
          if(response.status === 200){
            signIn({...response.data.data});
          }
        }catch(e){
          console.log(e)
          signOut();
        }
      };
      handleAuthentication();
    },[]);



  return waitAuthCheck ? (
    <h5>Loading ..888..</h5>
  ) : (
    <AuthContext.Provider value={{ isAuthenticated , user : user, signIn,signOut}}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
