import React,{ useState , useEffect} from "react";
import { Title, Switch } from "@tremor/react";
import axios from 'axios';

const EkonomiSection = ({companySelection,onCompanySelection,setCompanySelection,userId=null}) => {
    const [companies, setCompanies] = useState([]);
    
    const getCompanies = async () => {
        const response = await axios.get('/company/all');
        if(response.data.length > 0){
            const allCompanies = response.data;
            setCompanies(allCompanies);

            if(userId){
                const usercompaniesRes = await axios.get('/company/user?userId='+userId);
                if(usercompaniesRes.data.length > 0){
                    let temp = {};
                    allCompanies.forEach((company) => {
                        usercompaniesRes.data.includes(company.ID) ? temp[company.ID] = true : temp[company.ID] = false;
                    })
                    setCompanySelection(temp);
                }
            }else{
                let temp = {};
                response.data.forEach((company) => {
                    temp[company.ID] = false;
                })
                setCompanySelection(temp);
            }
        }   
    }

    useEffect(() => {
        getCompanies();
    },[])

    return (
        <div className="mt-6 mb-4">
            <Title className="mb-4">Företag</Title>
            {
                companies.map((company,idx) => {
                    return (
                        <div key={idx} className="flex flex-row items-center mt-2">
                            <Switch 
                                id="switch" 
                                name={company.ID} 
                                checked={companySelection[company.ID]} 
                                onChange={(value)=>onCompanySelection(company.ID,value)} 
                                />
                            <label htmlFor="switch" className="text-sm text-gray-500 ml-4">
                                {company.name}
                            </label>
                        </div>
                    )
                })
            }
        </div>
    );
};

export default EkonomiSection;