import React from 'react';
import { Card, Metric, Text } from "@tremor/react";

const TotalMetric = ({title,value,undertext}) => {

    return (
        <>
            <Card className="max-w-xs mx-auto mb-3 mt-3" decoration="left" decorationColor="indigo">
                <Text>{title}</Text>
                <Metric>{value}</Metric>
                { 
                    (undertext && undertext != "") ?
                        <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">{undertext}</p> 
                    : null
                }
            </Card>
        </>
    )
}

export default TotalMetric;