import React from 'react';
import { Title , Button} from "@tremor/react";
import {CpuChipIcon} from '@heroicons/react/24/solid' ;
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const Start = () => {
    const navigate = useNavigate();
    const {isAuthenticated} = useAuth()
    return (
        <div className="container mx-auto flex flex-col justify-center items-center w-full h-screen">
            <CpuChipIcon className="w-20 h-20 text-blue-500 mb-6" />
            <Title className="mb-12">Report Generator</Title>
            {
                isAuthenticated ? (
                    <Button onClick={()=>navigate('/app/dashboard')}>Dashboard</Button>
                ) : <Button onClick={()=>navigate('/signin')}>Signin</Button>
            }
            
        </div>
    );
}

export default Start;