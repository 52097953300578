import React from 'react';
import {Dialog, DialogPanel} from '@tremor/react';
import NewShift from '../Shift/NewShift';

const CustomShiftOverlay = ({closeNewShift,customShiftDriver}) => {  
    return (
        <Dialog open={true} onClose={(val) => closeNewShift()} static={true}>
            <DialogPanel className="max-w-5xl">
                <div >
                    <NewShift customShiftDriver={customShiftDriver} closeNewShift={closeNewShift}/>
                </div>
            </DialogPanel>
      </Dialog>
    )
}

export default CustomShiftOverlay;

