import React, { useEffect, useState } from "react";
import { useAuth } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import PulseLoader from "react-spinners/PulseLoader";

import SalaryHeader from "../components/Salary/SalaryHeader";
import SalaryTable from "../components/Salary/SalaryTable";
import SalaryDetails from "../components/Salary/SalaryDetails";
import SalaryStatus from "../components/Salary/SalaryStatus";
import SalaryCorrections from "../components/Salary/SalaryCorrections";
import SalaryApprove from "../components/Salary/SalaryApprove";
import SalaryShiftOverlay from "../components/Salary/SalaryShiftOverlay";
import CustomShiftOverlay from "../components/Salary/CustomShiftOverlay";

import dayjs from "dayjs";
import axios from "axios";

const Salary = () => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const [company, setCompany] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState(null);
  const [date, setDate] = useState(dayjs().set("date", 1).format("YYYY-MM-DD"));
  const [salaryData, setSalaryData] = useState(null);
  const [corrections, setCorrections] = useState([]);
  const [status, setStatus] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [comment, setComment] = useState("");
  const [adminComment, setAdminComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const [isLockingPasses, setIsLockingPasses] = useState(false);
  const [customShiftDriver, setCustomShiftDriver] = useState(null);

  useEffect(() => {
    async function getCompanies() {
      if (!date) return;

      const response = await axios.get("/company/available?startdate=" + date);
      setCompanies(response.data);
    }
    getCompanies();
  }, [date]);

  useEffect(() => {
    if (user?.rights === 5) setDriver(user.driverNo);
  }, [user]);

  useEffect(() => {
    if (!user || !isAuthenticated) {
      navigate("/signin");
      return;
    }
  }, [isAuthenticated, navigate, user]);

  useEffect(() => {
    async function getDrivers() {
      const response = await axios.get(
        "/drivers?akareNo=" + company.join(",") + "&startdate=" + date,
      );
      setDrivers(response.data);
    }
    if (company.length !== 0 && date && user.rights !== 5) getDrivers();
  }, [company, date, user]);

  async function getDriverData() {
    if (!date || company.length == 0 || !driver) return;

    setLoading(true);

    try {
      const response = await axios.get(
        `/salary?driverNo=${driver}&akareNo=${company}&startdate=${date}`,
      );
      if (response.data) {
        setSalaryData(response.data);
        if (response.data.approvedSalary) {
          setComment(response.data.approvedSalary.comment || "");
          setAdminComment(response.data.approvedSalary.adminComment || "");
        }
        getCorrections();
      }
    } catch (err) {
      console.log(err);
      setSalaryData(null);
      setCorrections([]);
    }
    setLoading(false);
    return;
  }
  useEffect(() => {
    getDriverData();
  }, [driver, company, date]);

  const getCorrections = async () => {
    try {
      const correctionsRes = await axios.get(
        `/corrections?driverNo=${driver}&companyId=${company}&startdate=${date}`,
      );
      if (correctionsRes.data) {
        setCorrections(correctionsRes.data);
      }
    } catch (err) {
      console.log(err);
      setCorrections([]);
    }
  };

  const approveCorrection = async (correction) => {
    try {
      const response = await axios.post("/corrections", {
        correction: correction,
        date: date,
      });
      if (response.status === 200) {
        getCorrections();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ignoreCorrection = async (correction) => {
    try {
      await axios.post("/corrections/ignore", correction);

      getCorrections();
    } catch (err) {
      console.log(err);
    }
  };

  const removeCorrection = async (correctionId) => {
    try {
      await axios.delete(`/corrections/${correctionId}`);
      getCorrections();
    } catch (err) {
      console.log(err);
    }
  };

  const inspectShift = (id) => {
    setSelectedShiftId(id);
  };

  const addCustomShift = async (driverNo) => {
    try {
      // Open Modal wirh <NewShift />
      setCustomShiftDriver(driverNo);
    } catch (err) {
      console.log(err);
    }
  };

  const closeNewShift = () => {
    getDriverData();
    setCustomShiftDriver(null);
  };

  const closeInspectShift = () => {
    getDriverData();
    setSelectedShiftId(null);
  };

  const onToggle = async (id) => {
    try {
      const response = await axios.post(`/shift/lock/${id}`);
      if (response.status === 200) {
        getDriverData();
        // alert("notification!")
      }
    } catch (err) {
      console.log(err);
    }
  };

  const lockPasses = async () => {
    try {
      setIsLockingPasses(true);
      const ids = salaryData.monthData
        .flat()
        .map((item) => item.id)
        .filter((item) => item !== "");
      const response = await axios.post(`/shifts/lock/all`, ids);

      if (response.status === 200) {
        getDriverData();
        toast.success("All shifts locked!");
      }
      setIsLockingPasses(false);
    } catch (err) {
      console.log(err);
    }
  };

  const addShift = async (shift) => {
    try {
      const response = await axios.post("/shifts", shift);
      if (response.status === 200) {
        getDriverData();
      }
    } catch (err) {
      console.log(err);
    }
  };




  const goToNextDriver = (direction) => async () => {
    const driverIndex = drivers.findIndex((d) => d.driverNo === driver);
    if (driverIndex === -1) return;
    if (driverIndex + direction < 0 || driverIndex + direction >= drivers.length) return;
    setDriver(drivers[driverIndex + direction].driverNo);
  };


  const approveSalary = async () => {
    try {
      const response = await axios.post("/salary/approve", {
        id: salaryData.approvedSalary?.ID,
        akareNo: company,
        comment: comment,
        adminComment: adminComment,
        date: date,
        totTime: salaryData.totWorkedData.totTid,
        breakTime: salaryData.totWorkedData.totBreaktime,
        workedTime: salaryData.totWorkedData.hoursWorked,
        ob1Time: salaryData.obHours.ob1,
        ob2Time: salaryData.obHours.ob2,
        cash: salaryData.cash.cash,
        totAmCash: salaryData.cash.totAmCash,
      });
      if (response.status === 200) {
        getDriverData();
      }
    } catch (err) {
      console.log(err);
    }
    setIsProcessing(false);
  };
  if (!isAuthenticated) {
    return null;
  }
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 lg:px-8 pt-4">
        <SalaryHeader
          setCompany={setCompany}
          setDriver={setDriver}
          setDate={setDate}
          setStatus={setStatus}
          company={company}
          companies={companies}
          driver={driver}
          drivers={drivers}
          date={date}
          status={status}
        />
        {loading ? <PulseLoader color="#36d7b7" size={5} /> : null}
        {!salaryData ? null : (
          <>
            <SalaryTable
              salaryData={salaryData}
              approvedCorrections={corrections.accepted_corrections}
              removeCorrection={removeCorrection}
              goToNextDriver={goToNextDriver}
            />
            <SalaryStatus
              salaryData={salaryData}
              approvedByDriver={salaryData.approvedSalary?.approvedByDriver}
              approvedByAdmin={salaryData.approvedSalary?.approvedByAdmin}
              completed_userID={salaryData.approvedSalary?.completed_userID}
            />
            <SalaryApprove
              approvedSalary={salaryData.approvedSalary}
              approveSalary={approveSalary}
              setComment={setComment}
              comment={comment}
              setAdminComment={setAdminComment}
              adminComment={adminComment}
              date={date}
            />
            <SalaryCorrections
              corrections={corrections.corrections}
              ignoreCorrection={ignoreCorrection}
              approveCorrection={approveCorrection}
              isProcessing={isProcessing}
            />
          </>
        )}
        {!salaryData ? null : (
          <SalaryDetails
            data={salaryData.monthTemp}
            onToggle={onToggle}
            lockPasses={lockPasses}
            inspectShift={inspectShift}
            closeInspectShift={closeInspectShift}
            selectedShiftId={selectedShiftId}
            isLockingPasses={isLockingPasses}
            addShift={addShift}
            driverNo={driver}
            addCustomShift={addCustomShift}
          />
        )}
      </div>

      {selectedShiftId ? (
        <SalaryShiftOverlay
          selectedShiftId={selectedShiftId}
          closeInspectShift={closeInspectShift}
        
        />
      ) : null}
      {customShiftDriver ? (
        <CustomShiftOverlay
          customShiftDriver={customShiftDriver}
          closeNewShift={closeNewShift}
        />
      ) : null}
    </>
  );
};

export default Salary;
