import React from "react";
import {
Card,
Table,
TableBody,
TableHead,
TableHeaderCell,
TableRow,
Title,
Button
} from "@tremor/react";
import ApprovedCorrections from "./ApprovedCorrections";
import SalaryTableRow from "./SalaryTableRow";
import { getFractionalHour , parseDecimalToToTime} from "../../lib/utils";
import { ArrowLongRightIcon, ArrowLongLeftIcon } from "@heroicons/react/24/outline";

const PROVISION_LON = 0.37;

const SalaryTable = ({salaryData,approvedCorrections,removeCorrection,goToNextDriver,rights}) => {
    if(!salaryData){
        return null;
    }
    const getCorrectionsTotal = () => {
        if(!approvedCorrections || approvedCorrections.length === 0){
            return 0;
        }
        return approvedCorrections.map(c => (c.OriginalValue - c.NewValue)).reduce((a, b) => a + b, 0);
    }

    const getProvisionSalary = () => {
        const moms = 0.0566;
        let total = 0;
    
        total = (salaryData.cash['totMoneyMadeInkTaxUI'] - (salaryData.cash['totMoneyMadeInkTaxUI'] * moms) ) * PROVISION_LON;
        return total;
    }

    return (
        <div className="mt-4 mb-2 w-full mr-6">
            <div className="flex flex-row justify-between">
                <div className="w-96">
                    <Title className="mb-6 mt-3 min-w-64">{salaryData.driver.name}</Title>
                </div>
                {
                    (rights === 5) ? null 
                        : <div>
                            <Button className="h-8 mt-3 ml-3 p-2" variant="primary" onClick={goToNextDriver(-1)} icon={ArrowLongLeftIcon}></Button>
                            <Button className="h-8 mt-3 ml-3 p-2" variant="primary" onClick={goToNextDriver(1)} icon={ArrowLongRightIcon }></Button>
                          </div>
                }
            </div>
            <Card>
                <Table className="">
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Namn</TableHeaderCell>
                            <TableHeaderCell>Värde</TableHeaderCell>
                            <TableHeaderCell>Alternate Value</TableHeaderCell>
                            {/* <TableHeaderCell>Godkänd</TableHeaderCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <SalaryTableRow rowData={ { label : "Inkört inkl moms (efter korrigeringar)" , value : salaryData.cash['totMoneyMadeInkTaxUI'], alternateValue : null , type : "currency", approved : salaryData.approved?.cash.totMoneyMadeInkTaxUI } } />
                        <SalaryTableRow rowData={ { label : "Inkört inkl moms (från taxameter)" , value : salaryData.cash['totMoneyMadeInkTax'], alternateValue : null , type : "currency", approved : salaryData.approved?.cash.totMoneyMadeInkTaxUI } } />
                        <SalaryTableRow style rowData={ { label : "Inkört exkl moms (från taxameter)" , value : salaryData.cash['totMoneyMade'], alternateValue : null , type : "currency" , approved : salaryData.approved?.cash.totMoneyMadeUI } } />
                        <SalaryTableRow rowData={ { label : "ProvisonLön" , value : getProvisionSalary(), alternateValue : null , type : "currency" , approved : (parseFloat(salaryData.approved?.cash.totMoneyMadeUI) * PROVISION_LON) } } />
                        {/* <SalaryTableRow rowData={ { label : "Rättelser Godkänd" , value : getCorrectionsTotal(), alternateValue : null , type : "currency" , approved : getCorrectionsTotal()} } />    */}
                    </TableBody>
                    </Table>
                        <ApprovedCorrections approvedCorrections={approvedCorrections} removeCorrection={removeCorrection} />
                    <Table>
                        <TableBody>
                            {/* <SalaryTableRow rowData={ { label : "Inkört efter korrigeringar inkl moms" , value : (salaryData.cash['totMoneyMadeInkTax'] + getCorrectionsTotal()), alternateValue : null, type : "currency", approved : (parseInt(salaryData.approved?.cash.totMoneyMadeInkTaxUI) + getCorrectionsTotal()) || 0 } } />
                            <SalaryTableRow rowData={ { label : "Inkört efter korrigeringar exkl moms" , value : (salaryData.cash['totMoneyMade']+ getCorrectionsTotal()), alternateValue : null , type : "currency",  approved : (parseInt(salaryData.approved?.cash.totMoneyMadeUI) + getCorrectionsTotal() ) || 0 } } /> */}
                            <SalaryTableRow style rowData={ { label : "BOM Totalt" , value : salaryData['bom'], alternateValue : null , type: "currency" , approved : salaryData.approved?.bom} } />
                            <SalaryTableRow rowData={ { label : "Inkört per timme exkl moms" , value : salaryData['revenuePerHour'], alternateValue : null , type : "currency" ,  approved : salaryData.approved?.revenuePerHour} } />
                            <SalaryTableRow style rowData={ { label : "Inkört per mil exkl moms" , value : salaryData['revenuePerSeMile'], alternateValue : null , type : "currency" ,  approved : salaryData.approved?.revenuePerSeMile } } />
                            <SalaryTableRow badge={salaryData['manualPassesTotal']} rowData={ { label : "Inloggad tid ( från taxameter )" , value : salaryData['totWorkedData']['totTid'], alternateValue : getFractionalHour(salaryData['totWorkedData']['totTid']) ,  approved : salaryData.approved?.approvedTotWorkedData?.totalTidUI} } />
                            <SalaryTableRow style rowData={ { label : "Rast (från taxameter )" , value : salaryData["totWorkedData"]['totBreaktime'], alternateValue : getFractionalHour(salaryData["totWorkedData"]['totBreaktime']) ,  approved : salaryData.approved?.approvedTotWorkedData?.totBreaktime} } />
                            <SalaryTableRow rowData={ { label : "Inloggad taxameter minus rast ( från taxameter )" , value : salaryData["totWorkedData"]['hoursWorked'], alternateValue : getFractionalHour(salaryData["totWorkedData"]['hoursWorked']) ,  approved : salaryData.approved?.approvedTotWorkedData?.hoursWorked } } />
                            {/* <SalaryTableRow rowData={ { label : "Kör och övrigt" , value : salaryData["manualTotWorkedData"]['hoursWorked'], alternateValue : getFractionalHour(salaryData["manualTotWorkedData"]['hoursWorked']) ,  approved : salaryData.approved?.approvedManualTotWorkedData?.hoursWorked } } /> */}
                            {/* <SalaryTableRow rowData={ { label : "Frånvaro - (sjuk och semester)" , value : "-"+salaryData["manualMinusTotWorkedData"]['hoursWorked'], alternateValue : "-"+getFractionalHour(salaryData["manualMinusTotWorkedData"]['hoursWorked']) ,  approved : "-"+salaryData.approved?.approvedManualTotWorkedData?.hoursWorkedUI } } /> */}
                            <SalaryTableRow style rowData={ { label : "Totalt timmarlön ( med manuell pass)" , value : salaryData["totalTotWorkedData"]['hoursWorkedUI'], alternateValue : getFractionalHour(salaryData["totalTotWorkedData"]['hoursWorkedUI']) ,  approved : salaryData.approved?.totalApprovedWorkedTime?.hoursWorkedUI } } />
                            <SalaryTableRow rowData={ { label : "OB1" , value : parseDecimalToToTime((salaryData.obHours['ob1'] < 0 ? 0 : salaryData.obHours['ob1'] )), alternateValue : salaryData.obHours['ob1'] < 0 ? 0 : salaryData.obHours['ob1'],  approved : parseDecimalToToTime(salaryData.approved?.obHours?.ob1) } } />
                            <SalaryTableRow style rowData={ { label : "OB2" , value : parseDecimalToToTime(salaryData.obHours['ob2']), alternateValue : salaryData.obHours['ob2'] ,  approved : parseDecimalToToTime(salaryData.approved?.obHours?.ob2) } } />
                            <SalaryTableRow rowData={ { label : "Kontant, Inlämnat" , value : salaryData.cash['cash'], alternateValue : null ,type : "currency",  approved : salaryData.approved?.cash.cash} } />
                            <SalaryTableRow style rowData={ { label : "Kontant, Inkört" , value : salaryData.cash['totAmCash'], alternateValue : null,type : "currency" ,  approved : salaryData.approved?.cash.totAmCash } } />
                            <SalaryTableRow rowData={ { label : "Kvar att redovisa" , value : salaryData.cash['Sum'], alternateValue : null ,type : "currency",  approved : salaryData.approved?.cash.Sum} } />
                        </TableBody>
                </Table>
            </Card>
        </div>
    );
}

export default SalaryTable;