import React, { useEffect , useState} from 'react';
import { Title , Button , Accordion, AccordionList, AccordionHeader, AccordionBody} from '@tremor/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { APP_ROUTES } from '../config/routes';
import ShiftTable from '../components/Shift/ShiftTable';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

const Shifts = () => {
    const [shifts, setShifts] = useState([]);
    const {isAuthenticated,user} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if(!user || !isAuthenticated){
            navigate('/signin')
        }
    }   , [isAuthenticated,navigate])

    const fetchShifts = async () => {
        try{
            const response = await axios.get('/shifts');
            const data = await response.data;
            if(response.status !== 200) {
                throw Error(response.message);
            }
            setShifts(data.shifts);
        }catch(err){
            console.log(err);
        }
    }

    useEffect(() => {
        fetchShifts();
    },[]);

    if(!isAuthenticated){
        return null;
    }
    
    return (
        <div className="container mx-auto w-[80%] pb-12">
            <Title className="mt-8 w-full text-center">Välkommen {user.name}</Title>
            <div className="w-full flex flex-row justify-end items-center mt-8 mb-8">
                {/* <Title className="">Redovisa Körpass</Title> */}
                <Button icon={PlusCircleIcon} size="xs" onClick={()=>navigate(APP_ROUTES.NEW_PASS)}>Registrera manuellt pass</Button>
            </div>

            <>
                <div className="text-md text-gray-500">{
                    (user.rights === 5) ? 'Oregistrerade pass' : 'Registrerade pass'
                }</div>
                <AccordionList className="w-full mx-auto mt-4">
                    {
                        Object.keys(shifts).map((key,index)=>(
                            <Accordion key={key}>
                                <AccordionHeader className="text-sm">{`${key} (${shifts[key].length})`}</AccordionHeader>
                                <AccordionBody>
                                    <ShiftTable key={index} shifts={shifts[key]} />
                                </AccordionBody>
                            </Accordion>
                        ))
                    }
                </AccordionList>
            </>
            
        </div>
    );
}

export default Shifts;