import React, { useEffect } from "react";
import { useAuth } from '../auth/AuthContext';

const Signout = () => {
    const {signOut} = useAuth();
    useEffect(() => {
        signOut();
    },[]);


    return (
        <div>
            <h1>Signout</h1>
        </div>
    );
}

export default Signout;