import React from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

const content = {
  carNo: "Här fylls fordonsnumret du kört i automatiskt",
  rapportNo:
    "Här fylls körpassrapportens nummer i automatiskt Körpassrapport står det och är unikt för varje pass du arbetat/loggat in i varje fordon.",
  startdatum:
    "Här fylls det i vilket datum du startade passet, programmet håller koll på om du kört över midnatt.",
  slutdatum: "Här fylls det i vilket datum du slutade passet",
  tid: "Här fylls tiden du loggar in i taxametern i som (START) och den tid du loggar ur taxametern (SLUT) Nedan kan du justera om dessa inte stämmer Starttid ska vara den tid du började att arbeta och sluttid ska vara den tid du slutade arbeta och här kan du göra en justering om START och SLUT inte överensstämmer med verkligheten. Programmet kommer automatiskt att be om en anledning för avvikelsen och den fyller du i under felanmälan ekonomi. Avdrag för rast görs separat nedan.",
  timeworked:
    "Programmet räknar ut hur många timmar du varit på jobbet utifrån det som är angivet under START och SLUT. Avdrag för raster görs under RAST",
  totaltinkort:
    "Här fylls det belopp i som står under (TOTALT INKÖRT, se bild) i automatiskt. Om du har missat att slå in något eller om beloppet inte stämmer så kan du justera det här. Du måste också ange orsak i fältet felanmälan ekonomi. Om du är osäker så ändra inte beloppet utan gör bara en felanmälan till ekonomi och försök förklara vad som gått fel.",
  redovisas:
    "Här fylls det belopp i som visas på bilden automatiskt. Om du fått dricks på en kortbetalning så är den redan tillgodoräknad här och minskar mängden kontanter du behöver redovisa. För utlägg mot kvitto, se nedan i egen rubrik.",
  utlagg:
    "Om du har ett kvitto för något du behövt göra ett utlägg för redovisar du det här. Kvittot som du har skickar du med i kuvertet med kontanter.Du måste alltid redovisa ett kvitto på det du köpt för egna pengar för att vi ska kunna ersätta utlägget.",
};

const TooltipMarker = ({ place, contentKey }) => {
  return (
    <>
      <QuestionMarkCircleIcon
        className="h-4 w-5 inline-block"
        data-tooltip-class-name={`tooltip-master`}
        data-tooltip-id={"my-tooltip"}
        data-tooltip-html={content[contentKey]}
        data-tooltip-place={place}
      />
    </>
  );
};

export default TooltipMarker;
