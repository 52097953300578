const { APP_ROUTES } = require("./routes");

export const tabs = [
  { name: "Dashboard", href: APP_ROUTES.DASHBOARD, rights: [2, 3, 5, 1] },
  { name: "Löneunderlag", href: APP_ROUTES.SALARY, rights: [2, 3, 5, 1, 6] },
  { name: "Pass", href: APP_ROUTES.SHIFTS, rights: [2, 3, 5, 1] },
  { name: "Användare", href: APP_ROUTES.USERS, rights: [2, 1] },
  { name: "Kontant", href: APP_ROUTES.REGISTER, rights: [2, 1, 6] },
  { name: "Excel utskrifter", href: APP_ROUTES.EXCEL, rights: [2, 1] },
];
