import React from "react";
import { Grid, Col, Title , Divider} from "@tremor/react";
import CardLineChart from "./LineChart";
import CardDoughnutChart from "./DoughnutChart";
import TotalMetric from "./TotalMetric";
import { convertToCurrency } from "../../lib/utils";

const OverAllView = ({statsData,moms}) => {
    
    return (
        <Grid  numItems={4}>
            {
                (statsData?.totaltoPay) ? (
                    <>
                        <Col numColSpan={4}>
                            <Divider>Inkört belopp</Divider>
                        </Col>
                        <CardLineChart 
                            index={"day"} 
                            title={`Inkört ${ (moms) ? 'Inkl. moms' : 'Exkl. moms'}`} 
                            labels={[(moms) ? "Inkört inkl moms" : "Inkört exkl moms", "bom"]} 
                            valueFormatter={(number) => `${convertToCurrency(number)}`}
                            data={statsData?.totaltoPay} />
                    </>
                ) : null
            }
            {
                (statsData?.totals?.bom) ? (
                    <Col numColSpan={2}>
                        <TotalMetric value={(moms) ? convertToCurrency(statsData?.totals.bom) : convertToCurrency(statsData?.totals.bom - (statsData?.totals.bom*0.06))} title={"Totalt Bom"} undertext={`${ (moms) ? 'Inkl. moms' : 'Exkl. moms'}`}/>
                    </Col>
                ) : null
            }
            {
                (statsData?.totals?.totMadeInkTax) ? (
                    <Col numColSpan={2}>
                        <TotalMetric value={
                            (moms) ?
                            convertToCurrency(statsData?.totals.totMadeInkTax)
                            : 
                            convertToCurrency(statsData?.totals.totMade)} title={"Total Salaries"}
                            undertext={`${ (moms) ? 'Inkl. moms' : 'Exkl. moms'}`}
                            />
                    </Col>
                ) : null
            }
            {
                (statsData?.totaltoPay) ? (
                    <>
                        <Col numColSpan={4}>
                            <Divider>Sträk</Divider>
                        </Col>
                        <CardLineChart index={"day"} 
                            title="Sammanställning mil" 
                            labels={["Totalt antal mil", "Totalt antal betalda mil","Totalt antal obetalda mil"]} 
                            data={statsData?.totaltoPay.map((day)=>{day['Totalt antal obetalda mil'] = (day['Totalt antal betalda mil']-day['Totalt antal mil']);return day;})} 
                            valueFormatter={(value)=>`${value} km`}
                        />
                    </>
                ) : null
            }

{
                (statsData?.totaltoPay) ? (
                    <>
                        <Col numColSpan={4}>
                            <Divider>Tid</Divider>
                        </Col>
                        <CardLineChart index={"day"} 
                            title="Sammanställning timmar" 
                            labels={["Totalt antal timmar", "Totalt antal Rast timmar"]} 
                            data={statsData?.totaltoPay} 
                            valueFormatter={(value)=>`${value} timmar`}
                        />
                    </>
                ) : null
            }

           
            {
                (statsData?.totals?.hoursWorked) ? (
                    <Col numColSpan={2}>
                        <CardDoughnutChart 
                            index={"name"} 
                            category={"value"} 
                            title="Timmar" 
                            valueFormatter={ (number) => `${Math.round(number)} timmar` }
                            data={[
                                {name : "Betalda timmar", value: parseInt(statsData.totals.paidTime) },
                                {name : "Obetalda timmar", value: (parseInt(statsData.totals.totalBreaks)+parseInt(statsData.totals.hoursWorked))-parseInt(statsData.totals.paidTime)} , 
                            ]} />
                    </Col>
                ) : null
            }
            {
                (statsData?.totals?.distanceDone) ? (
                    <Col numColSpan={2}>
                        <CardDoughnutChart 
                            index={"name"} 
                            category={"value"} 
                            title="Totala betalda mil " 
                            valueFormatter={ (number) => `${Math.round(number)} mil` }
                            data={[{name : "Betalda mil", value: parseInt(statsData.totals.distancePaid)} , {name : "Obetalda mil", value: parseInt(statsData.totals.distanceDone)-parseInt(statsData.totals.distancePaid)} , ]} />
                    </Col>
                ) : null

            }
        </Grid>
    );

}

    export default OverAllView;
