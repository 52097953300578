import React, { useEffect , useState} from 'react';
import { useNavigate , useParams} from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { 
    TextInput,
    Card,
    Button,
    Text,
    Title,
    Switch
} from "@tremor/react";
import BreadCrumbNav from '../components/BreadCrumbNav';
import AdminSection from '../components/User/AdminSection';
import EkonomiSection from '../components/User/EkonomiSection';
// import DriverSection from '../components/User/DriverSection';
import axios from 'axios';

const User = () => {
    const {isAuthenticated,user} = useAuth()
    const navigate = useNavigate()
    const [selectedUser, setUser] = useState(null);
    const {userId} = useParams();
    const [companySelection, setCompanySelection] = useState({});

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/signin')
        }
    }   , [isAuthenticated,navigate])
    
    const fetchUser = async () => {
        try{
            const response = await axios.get(`/user/${userId}`);
            if(response.status === 200){
                console.log(response.data.data)   
                setUser(response.data.data);
            }
        }catch(err){
            console.log(err);
        }
    } 

    /**
     * Get Users
     */
    useEffect(() => {
        fetchUser();
    },[]);


    /**
     * Handle Submit
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try{
            let data = new FormData(event.currentTarget);
            data = Object.assign({},data,selectedUser)
            const response = await axios.put('/user/'+userId,data);
            if(response.status === 200){
                console.log(response);
                navigate('/app/users')
            }else{
                console.log(response);
            }
        }catch(err){
            console.log(err);
        }
    }

    /**
     * Validate
     */
    const validate = () => {
        return ( selectedUser.email === "" || selectedUser.password === "" || selectedUser.name === "" || selectedUser.driverType === "");
    }

   
    const onChange = (value,name) => {
        let mutation = {};
        if(typeof value === 'object'){
            mutation = value;
        }else{
            mutation[name] = value;
        }
        const updatedUser = Object.assign({}, selectedUser, mutation);
        setUser(updatedUser);
    }

    const onCompanySelection = (id,value) => {
        const mutation = {...companySelection,[id]:value}
        setCompanySelection(mutation)
        onChange(mutation,'companies')
    }

    /**
     * Mount subsection of the form
     */
    const mountSection = () => {
        switch (selectedUser.rights) {
            case 2:
                return <AdminSection onSectionChange={onChange} 
                            companySelection={companySelection} 
                            onCompanySelection={onCompanySelection} 
                            setCompanySelection={setCompanySelection} 
                            userId={userId}
                            user={user}
                            />           
            case 6:
                return <EkonomiSection onSectionChange={onChange} 
                            companySelection={companySelection} 
                            onCompanySelection={onCompanySelection} 
                            setCompanySelection={setCompanySelection}
                            userId={userId}
                            />
            case 3:
                return null
            default:
                return null
        }
    }

    return (
        <div className="container mx-auto flex flex-col justify-center items-center">
            <BreadCrumbNav />
            <Card className="mt-12 max-w-screen-md p-8" >
              <Title>Användare</Title>
              { (!selectedUser) ? <Text className="mt-4">Tom eller så har du inte behörighet att redigera den här användaren</Text> : (

                <form onSubmit={handleSubmit}>
                    <div className="mt-4 mb-2">
                        <Text className="mb-2">Namn</Text>
                        <TextInput name="name" value={selectedUser.name} onChange={(e)=>onChange(e.target.value,'name')} placeholder="Jon Svensson" type="text" />
                    </div>
                    <div className="mt-4 mb-2">
                        <Text className="mb-2">Email</Text>
                        <TextInput name="email" value={selectedUser.email} onChange={(e)=>onChange(e.target.value,'email')} placeholder="user@email.com" type="text" />
                    </div>
                    <div className="mt-4 mb-2">
                        <Text className="mb-2">Lösenord</Text>
                        <TextInput name="password" value={selectedUser.password} onChange={(e)=>onChange(e.target.value,'password')} placeholder="***********" type="password" />
                    </div>
                    <div className="mt-4 mb-2">
                        <Text className="mb-2">Aktiv</Text>
                        <div className="flex flex-row items-center mt-2">
                            <Switch 
                                id="switch" 
                                name={"status"} 
                                checked={selectedUser.active} 
                                onChange={(value)=>onChange(value,'active')} 
                                />
                            <label htmlFor="switch" className="text-sm text-gray-500 ml-4">
                                { (selectedUser.active) ? 'Aktiv' : 'Inaktiv' }
                            </label>
                        </div>
                    </div>
                    { mountSection() }
                    <Button disabled={validate()} className="mt-6 w-full" size="xs" type="submit" >Uppdatering</Button>
                </form>
              ) }
            </Card>
        </div>
    )
}

export default User