import React,{ useEffect, useState } from "react";
import { Text, TextInput, Switch, Title,} from "@tremor/react";
import axios from "axios";

const VaxelPersonalSection = ({companySelection,onCompanySelection,selectedUser,setCompanySelection,userId = null, onSectionChange}) => {
    const [driverNumber, setDriverNumber] = useState(selectedUser?.driverNo);
    const [companies, setCompanies] = useState([]);

    const getCompanies = async () => {
        const response = await axios.get('/company/all');
        if(response.data.length > 0){
            const allCompanies = response.data;
            setCompanies(allCompanies);

            if(userId){
                const usercompaniesRes = await axios.get('/company/user?userId='+userId);
                if(usercompaniesRes.data.length > 0){
                    let temp = {};
                    allCompanies.forEach((company) => {
                        usercompaniesRes.data.includes(company.ID) ? temp[company.ID] = true : temp[company.ID] = false;
                    })
                    setCompanySelection(temp);
                }
            }else{
                let temp = {};
                response.data.forEach((company) => {
                    temp[company.ID] = false;
                })
                setCompanySelection(temp);
            }
        }   
    }

    useEffect(() => {
        getCompanies();
    },[])

    useEffect(() => {
        onSectionChange(parseInt("777"+driverNumber),'driverNumber');
    },[driverNumber]);

    return (
        <div>
            <div className="mt-4 mb-2">
                <Text className="mb-2">Anställningsnummer</Text>
                <TextInput name="text" onChange={(e)=>setDriverNumber(e.target.value)} placeholder="101101101" defaultValue={driverNumber} type="text" />
            </div>
            <div className="mt-6 mb-4">
            <Title className="mb-4">Företag</Title>
            {
                companies.map((company,idx) => {
                    return (
                        <div key={idx} className="flex flex-row items-center mt-2">
                            <Switch 
                                id="switch" 
                                name={company.akareNo} 
                                checked={companySelection[company.akareNo]} 
                                onChange={(value)=>onCompanySelection(company.akareNo,value)} 
                                disabled={selectedUser?.id === parseInt(userId)}
                                />
                            <label htmlFor="switch" className="text-sm text-gray-500 ml-4">
                                {company.haldaName}
                            </label>
                        </div>
                    )
                })
            }
        </div>
        </div>
    );
};

export default VaxelPersonalSection;