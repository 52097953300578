const dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc')
var duration = require('dayjs/plugin/duration')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

exports.getFractionalHour = function(time) {
    let parts = time.split(':');
    let minutes = Math.round(parts[1] / 60 * 100) / 100;
    let newTime = parseFloat(parts[0]) + minutes;
    const rounded = Math.round((newTime + Number.EPSILON) * 100) / 100
    return rounded;
}

exports.parseDecimalToToTime = function(decimal) {
    const hours = Math.floor(decimal);
    const minutes = Math.round((decimal - hours) * 60);
    const time = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    return time;
}

exports.convertToCurrency = function (value) {
    const currency = new Intl.NumberFormat('en-SE', { style: 'currency', currency: 'SEK' }).format(value);
    return currency;    
}

exports.minutesToTimeStamp = function (minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
}


exports.formatDate = function (date) {
    if(!date) return date;
    // Remove .000Z and remove T
    return date.split("T")[0].replace('.000Z', '');
}

exports.getStartDate = function (startdate) {
    // First day of the month
    const date = new Date(startdate);
    date.setDate(1);
    return date.toISOString().split('T')[0];
}

exports.formatTime = function (time) {
    if(!time) return time;
    if(time.includes("T")){
        const retTime = dayjs(time).tz("UTC").format("HH:mm")
        return retTime;
    }
    return time;
}

exports.calculateTotalHours = function (shift) { 
    if(!shift.report) return 0;

    if(!shift.report.enddate || !shift.report.startdate || !shift.report.endtimeUI || !shift.report.starttimeUI ) return 0;

    // Hours Worked
    let starttimeUI = module.exports.formatTime(shift.report.starttimeUI);
    let endtimeUI = module.exports.formatTime(shift.report.endtimeUI);
    let startdate = module.exports.formatDate(shift.report.startdate);
    let enddate = module.exports.formatDate(shift.report.enddate);

    const start = dayjs(startdate+"T"+starttimeUI);
    const end = dayjs(enddate+"T"+endtimeUI);

    const duration = dayjs.duration(end.diff(start));
    const totalDuration = duration.asMinutes();

    // Breaks Duration
    let breakDuration = 0;

    if(shift.breaks) {
        shift.breaks.forEach(breakItem => {
            if(!breakItem.starttime || !breakItem.endtime) return;
            //Figure out if time has a date stamp
            let breakStart = breakItem.starttime;
            let breakEnd = breakItem.endtime;

            if(!breakStart.includes("T") || !breakEnd.includes("T")){
                breakStart = dayjs(shift.report.startdate+"T"+breakItem.starttime);
                // have to check if it is the next day
                breakEnd = dayjs(shift.report.startdate+"T"+breakItem.endtime);
            }else{
                breakStart = dayjs(breakItem.starttime);
                breakEnd = dayjs(breakItem.endtime);
            }

            const duration = dayjs.duration(breakEnd.diff(breakStart));
            breakDuration += duration.asMinutes();
        });
    }
    
    const displayDuration = dayjs.duration(totalDuration-breakDuration,'minutes').format('HH:mm')
    if(isNaN(totalDuration-breakDuration)) return "Check values: dates and times";
    
    return displayDuration
}


exports.getTotalDrivenPercentages = (statsData) => {
    const result = [
        {name : "Paid Dist", value : statsData?.drivenTotal.dist_paid / 1000},
        {name : "Unpaid Dist", value : (statsData?.drivenTotal.dist_taxi - statsData?.drivenTotal.dist_paid) / 1000}
    ]

    return result;
}

exports.getTotalHoursWorked = (statsData) => {
    const {totalWorked, totalBreak} = statsData?.totalHours;
      
    const result = [
        {name : "worked", value : totalWorked},
          {name : "break", value : totalBreak},
    ]

    return result;
}

exports.validateTime = (value) => {
    if(!value) return true;

    const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    const valid =  timeRegex.test(value);
    return valid;
}

exports.getFormattedDate = (date) => {  
    const dateObj = new Date(date);
    const month = dateObj.getMonth() + 1;
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${year}-${month}-${day}`;
}


exports.getReportType  = (type) => {
    const types = {
        1: 'Manuell',
        2: 'Semester',
        3: 'Sjuk',
        4: 'Övrigt',
    }
    return types[type];
} 

exports.holidays = [
    "01-01-2024",
    "06-01-2024",
    "29-03-2024",
    "30-03-2024",
    "31-03-2024",
    "01-04-2024",
    "01-05-2024",
    "09-05-2024",
    "06-06-2024",
    "21-06-2024",
    "22-06-2024",
    "24-12-2024",
    "25-12-2024",
    "26-12-2024",
    "31-12-2024",
    "01-01-2025",
    "06-01-2025",
    "18-04-2025",
    "19-04-2025",
    "20-04-2025",
    "21-04-2025",
    "01-05-2025",
    "29-05-2025",
    "06-06-2025",
    "20-06-2025",
    "21-06-2025",
    "24-12-2025",
    "25-12-2025",
    "26-12-2025",
    "31-12-2025",
    "01-01-2026",
    "26-01-2026",
    "03-04-2026",
    "04-04-2026",
    "05-04-2026",
    "06-04-2026",
    "01-05-2026",
    "14-05-2026",
    "06-06-2026",
    "19-06-2026",
    "20-06-2026",
    "24-12-2026",
    "25-12-2026",
    "26-12-2026",
    "31-12-2026",
  ];