import React from "react";
import { Button } from "@tremor/react";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";

const FileUploader = ({ type, id, success }) => {
  const [file, setFile] = React.useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    console.log(formData);
    const url = `${process.env.REACT_APP_BASE_URL_API}/shifts/attachments/${type}/${id}`;
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((data) => {
        setFile(null);
        if (type === "adm") {
          success(formData.get("adm").name, "errorImgAdm");
        } else if (type === "vehicle") {
          success(formData.get("vehicle").name, "errorImgVehicle");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  };

  return (
    // Note that there will be nothing logged when files are dropped
    <form
      onSubmit={onSubmit}
      method="post"
      encType="multipart/form-data"
      className="mb-6"
    >
      <input
        id={`file-input-${type}`}
        type="file"
        onChange={onChange}
        name={type}
        hidden
      />
      <label
        htmlFor={`file-input-${type}`}
        className="cursor-pointer mr-6 border p-2 rounded-md border-slate-300 hover:bg-gray-100 hover:border-gray-400 text-sm"
      >
        {file?.name ? file.name : "Välj fil"}
      </label>

      <input type="hidden" name="type" value={type} />
      <Button
        size="xs"
        className=""
        disabled={!file}
        icon={ArrowUpOnSquareIcon}
        type="submit"
      >
        Skicka in
      </Button>
    </form>
  );
};

export default FileUploader;
