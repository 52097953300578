const APP_ROUTES = {
  HOME: "/",
  SIGNIN: "/signin",
  SIGNUP: "/app/signup",
  SIGNOUT: "/signout",
  SHIFTS: "/app/shifts",
  SALARY: "/app/salary",
  EXCEL: "/app/excel",
  APP: "/app",
  USERS: "/app/users",
  REGISTER: "/app/register",
  SCHEDULE: "/app/schedule",
  NEW_PASS: "/app/shift/new",
  DASHBOARD: "/app/dashboard",
  CARLOANS: "/app/carloans",
  NEWLOAN: "/app/carloans/new",
};

const API_ROUTES = {
  USERS: "/users",
  DRIVERS: "/drivers",
  SALARY: "/salary",
  SCHEDULE: "/schedule",
  CARLOANS: "/loans",
};

export { APP_ROUTES, API_ROUTES };
