import React from "react";
import {
  SearchSelect,
  SearchSelectItem,
  Text,
  Col,
  DatePicker,
  Grid,
  MultiSelect,
  MultiSelectItem,
} from "@tremor/react";
import { useAuth } from "../../auth/AuthContext";
import dayjs from "dayjs";
const SalaryHeader = ({
  setCompany,
  setDriver,
  setDate,
  company,
  driver,
  drivers,
  date,
  companies,
  overall = false,
}) => {
  const { user } = useAuth();
  const onChangeDate = (value) => {
    let date = dayjs(value);
    date = date.date(1);
    let newDate = "";
    newDate = date.format("YYYY-MM-DD");

    setDate(newDate);
  };

  const onCompanyChange = (value) => {
    console.log(value);
    setCompany(value);
    if (user.rights !== 5) {
      setDriver(null);
    }
  };

  return (
    <Grid numItems={3} numItemsLg={3} numItemsMd={3} numItemsSm={3}>
      <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1}>
        <div className="mt-4 mb-2  mr-6">
          <Text className="mb-2">Månad</Text>
          <DatePicker
            value={!date ? new Date() : new Date(date)}
            displayFormat="yyyy-MM-dd"
            onValueChange={onChangeDate}
          />
          <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
            Välj månad
          </p>
        </div>
      </Col>
      <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1}>
        <div className="mt-4 mb-2  mr-6">
          <Text className="mb-2">Företag</Text>
          <MultiSelect
            value={company || ""}
            onValueChange={(value) => onCompanyChange(value)}
          >
            {companies.map((company, idx) => (
              <MultiSelectItem key={idx} value={company.akareNo}>
                {company.haldaName}
              </MultiSelectItem>
            ))}
          </MultiSelect>
          <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
            Välj företag
          </p>
        </div>
      </Col>

      <Col numColSpan={3} numColSpanMd={1} numColSpanSm={3} numColSpanLg={1}>
        {user.rights === 5 || overall ? null : (
          <div className="mt-4 mb-2  mr-6">
            <Text className="mb-2">Förare</Text>
            <SearchSelect
              value={driver || ""}
              onValueChange={(value) => setDriver(value)}
            >
              {drivers.map((driver, idx) => (
                <SearchSelectItem key={idx} value={driver.driverNo}>
                  {driver.name} - {driver.driverNo}
                </SearchSelectItem>
              ))}
            </SearchSelect>
            <p className="mt-4 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
              Välj förare
            </p>
          </div>
        )}
      </Col>
    </Grid>
  );
};

export default SalaryHeader;
