import React from "react";
import {
    Table,
    TableBody,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    TableCell,
    Title,
    Card,
    Button
    } from "@tremor/react";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/24/outline";
import {convertToCurrency} from "../../lib/utils";
import { useAuth } from "../../auth/AuthContext";

const SalaryCorrections = ({corrections,approveCorrection,ignoreCorrection}) => {

    const {user} = useAuth()
 
    if(!corrections || corrections.length === 0){
        return null;
    }

    return (
            <>
            <Card className="mt-8">
                <Title>Corrections</Title>
                <Table className="w-full">
                        <TableHead className="w-full">
                            <TableHeaderCell className="text-xs text-center">NotaNr</TableHeaderCell>
                            <TableHeaderCell className="text-xs text-center">BilNr</TableHeaderCell>
                            <TableHeaderCell className="text-xs text-center">Datum</TableHeaderCell>
                            <TableHeaderCell className="text-xs text-center">Kundkod</TableHeaderCell>
                            <TableHeaderCell className="text-xs text-center">Kvitto Total</TableHeaderCell>
                            <TableHeaderCell className="text-xs text-center">Korrigerade värde</TableHeaderCell>
                            <TableHeaderCell className="text-xs text-center">Skillnad</TableHeaderCell>
                            <TableHeaderCell className="text-xs text-center"></TableHeaderCell>
                        </TableHead>
                        <TableBody> 
                            {
                                corrections.map((c,idx)=>(
                                    <TableRow key={idx}>
                                        <TableCell>
                                            <Text className="text-xs text-center">{c.NotaNr}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-xs text-center">{c.Bilnr}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-xs text-center">{new Date(c.date).toISOString().split("T")[0]}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-xs text-center">{c.Kundkod}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-xs text-center">{convertToCurrency(c.OriginalTotal)}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-xs text-center">{convertToCurrency(c.NewValue)}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text className="text-xs text-center">{convertToCurrency(c.OriginalTotal - c.NewValue)}</Text>
                                        </TableCell>
                                        {
                                            (user.rights === 5) ? null : (
                                                <TableCell>
                                                    <Button onClick={()=>ignoreCorrection(c)} icon={MinusCircleIcon} variant="light" color="red" className="ml-1"></Button>
                                                    <Button onClick={()=>approveCorrection(c)} icon={CheckCircleIcon} variant="light" color="green" className="ml-1"></Button>
                                                </TableCell>
                                            )
                                        }
                                        
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
            </Card>
            </>
    )
}

export default SalaryCorrections;