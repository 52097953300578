import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TableHeaderCell, Text} from '@tremor/react';
import {formatTime} from "../lib/utils";
import { TrashIcon } from "@heroicons/react/24/outline";

const BreaksTable = ({removeBreak,breaks,locked}) => {
    return (
        (breaks.length === 0) ? <div className="font-light text-yellow-600">Inga rast tillagda</div> :
            (
                <Table className="mt-5 w-full">
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell></TableHeaderCell>
                            <TableHeaderCell>Start</TableHeaderCell>
                            <TableHeaderCell>Slut</TableHeaderCell>
                            <TableHeaderCell className="w-[80px]"></TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            breaks.map((b,idx)=>(
                                <TableRow key={idx}>
                                    <TableCell>Rast {idx+1}</TableCell>
                                    <TableCell>
                                        <Text>{formatTime(b.starttime)}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text>{formatTime(b.endtime)}</Text>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            (b.manuallyReported && !locked) ? (
                                                <TrashIcon onClick={()=>removeBreak(b.Id)} className="w-5 h-5 hover:stroke-rose-500 cursor-pointer" />
                                            ) : null
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            )
    );
}

export default BreaksTable; 