import React from 'react';
import {Dialog, DialogPanel} from '@tremor/react';
import Shift from '../../pages/Shift';

const SalaryShiftOverlay = ({ selectedShiftId , closeInspectShift, unlockShift}) => {
    return (
        <Dialog open={true} onClose={(val) => closeInspectShift()} static={true}>
            <DialogPanel className="max-w-5xl">
                <div >
                    <Shift selectedShiftId={selectedShiftId} closeInspectShift={closeInspectShift} unlockShift={unlockShift}/>
                </div>
            </DialogPanel>
      </Dialog>
    )
}

export default SalaryShiftOverlay;