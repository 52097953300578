import React from 'react';
import { TextInput , Text, Col, Button} from '@tremor/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { formatTime, validateTime } from '../../lib/utils';


const AddBreak = ({ addBreak }) => {
    const [starttime, setStarttime] = React.useState('');
    const [endtime, setEndtime] = React.useState('');

    return (
        <>
            <Col numColSpan={2}>
                <Text>Start</Text>
                <TextInput 
                    value={starttime} 
                    placeholder="00:00" 
                    error={!validateTime(formatTime(starttime))} 
                    errorMessage={"Var vänlig att ange tiden i formatet: TT:MM"} 
                    onChange={(e)=>setStarttime(e.target.value)} />
            </Col>
            <Col numColSpan={2}>
                <Text>Slut</Text>
                <TextInput  
                    value={endtime} 
                    error={!validateTime(formatTime(endtime))} 
                    errorMessage={"Var vänlig att ange tiden i formatet: TT:MM"} 
                    placeholder="00:00" 
                    onChange={(e)=>setEndtime(e.target.value)} />
            </Col>
            <Col numColSpan={4}>
                <Button 
                    className="mt-2 mb-4 float-right" 
                    icon={PlusIcon} size="xs" 
                    color="green" 
                    disabled={(starttime === ""  || endtime === "") || !validateTime(formatTime(starttime)) || !validateTime(formatTime(endtime))}
                    onClick={()=>{addBreak(starttime , endtime);setStarttime("");setEndtime("")}}>               
                    Lägg till Rast
                </Button>
            </Col>
        </>
    );
}

export default AddBreak;