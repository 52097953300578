import { Title , Switch , Text, Col, Grid, Divider} from '@tremor/react';
import { useNavigate } from 'react-router-dom';
import React, { useEffect , useState} from 'react'
import axios from 'axios';
import DashboardHeader from '../components/Dashboard/DashboardHeader';
import { useAuth } from '../auth/AuthContext'
import dayjs from 'dayjs';
import UserView from '../components/Dashboard/UserView';
import OverAllView from '../components/Dashboard/OverallView';
import PulseLoader from "react-spinners/PulseLoader";  
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)


const Dashboard = () => {
    const {isAuthenticated,user} = useAuth()
    const navigate = useNavigate()

    const [statsData,setStatsData] = useState(null);
    const [selected,setSelected] = useState(null);
    const [isOverall, setIsOverall] = useState(false);
    
    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState([]);
    
    const [drivers, setDrivers] = useState([]);
    const [driver, setDriver] = useState(null);

    const [car, setCar] = useState(null);
    const [cars, setCars] = useState([]);

    const [moms, setMoms] = useState(true);

    const [startdate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [enddate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));

    const [fetchingData, setFetchingData] = useState(false);

    
    useEffect(() => {
        if(!user || !isAuthenticated){
            console.log("Not authenticated")
            navigate('/signin')
            return;
        }

        if(user && user?.rights === 5)
            setDriver(user.driverNo);

    }, [isAuthenticated,navigate,user])


    async function getCompanies(){
        if(!startdate || !enddate)
            return;

        const response = await axios.get(`/company/available?startdate=${startdate}&enddate=${enddate}`); 
        setCompanies(response.data);
    }


    useEffect(() => {
        getCompanies();
        if((company && startdate && enddate && driver) || (user?.rights === 2 && startdate && enddate && isOverall && company)){
            getStats();
        }
    },[startdate,enddate]);


    useEffect(() => {
        async function getDrivers(){
            const response = await axios.get(`/drivers?akareNo=${company}&startdate=${startdate}&enddate=${enddate}`);
            setDrivers(response.data);
        }

        async function getCars(){
            const response = await axios.get(`/cars?akareNo=${company}&startdate=${startdate}&enddate=${enddate}`);
            setCars(response.data);
        }

        if(company && startdate && enddate && (user.rights === 2 || user.rights === 1 ) && !isOverall){
            getDrivers();
            getCars();
        }
    },[company,startdate,enddate]);


    useEffect(() => {   
        if(company && (driver || car) && startdate && enddate)
            getStats();
        if(company && startdate && enddate && (user.rights === 2 || user.rights === 1 ) && isOverall)
            getStats()
    },[driver,company,startdate,enddate,isOverall,car]);


    const getStats = async () => {
    try{
        setFetchingData(true);
        const optionalParams = `${ (driver) ? '&driverNo='+driver : '' }${ (car) ? '&carNo='+car : ''}`
        const response = await axios.get(`/dashboard/stats?akareNo=${company}&startdate=${startdate}&enddate=${enddate}&overall=${isOverall}${optionalParams}`);
    
        if(response.data.length !== 0){
            const statsData = response.data;
            setStatsData(statsData);
        }

    }catch(err){
        console.log(err);
    }
        setFetchingData(false);
    }

    const toggleOverallSwitch = (value) => {
        if(!value){
            setStatsData(null);
        }
        setDriver(null);
        setCar(null);
        setSelected(null);
        setIsOverall(value)
    }

    if(!isAuthenticated){
        return null;
    }
console.log(moms)
    return (
        <div className="mx-auto max-w-7xl px-4 lg:px-8">
            <Title className="mt-6">Dashboard</Title>
            <DashboardHeader 
                setCompany={setCompany}
                setDriver={setDriver}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                company={company}
                companies={companies}
                driver={driver}
                drivers={drivers}
                startdate={startdate}
                enddate={enddate}
                overall={isOverall}
                setOverall={setIsOverall}
                car={car}
                cars={cars}
                setCar={setCar}
                setSelected={setSelected}
            />
            <Divider>Options</Divider>
            <Grid numItems={6}>
                <Col numColSpan={3} numColSpanSm={6} numColSpanMd={3} >
                    {
                        ((user.rights === 2 || user.rights === 1 )) ? (
                        <>
                            <div className="flex flex-row items-center mt-4">
                                <Switch name="switch_overall" className="mr-2 mt-2" onChange={(value) => {toggleOverallSwitch(value)}} value={isOverall} />
                                <label htmlFor="switch_overall" className="text-sm text-gray-500 ">
                                Företagsöversikt
                                </label>
                            </div>
                            <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                             Företagsöversikt visar företagets övergripande statistik
                            </p>
                        </>
                        ) : null
                    }
                </Col>
                <Col numColSpan={3} numColSpanSm={6} numColSpanMd={3}>
                    <>
                        <div className="flex flex-row items-center mt-4">
                            <Switch name="switch_overall" className="mr-2 mt-2" onChange={(value) => {setMoms(value)}} value={moms} checked={moms} />
                            <label htmlFor="switch_overall" className="text-sm text-gray-500 ">
                            Moms
                            </label>
                        </div>
                        <p className="text-tremor-label text-tremor-content dark:text-dark-tremor-content mb-4">
                        Visa värden exkl moms
                        </p>
                    </>
                </Col>
            </Grid>
            
            {
                fetchingData ? <PulseLoader color="#36d7b7"  size={5} /> :
                (isOverall) ? <OverAllView statsData={statsData} moms={moms} /> : <UserView statsData={statsData} driver={driver} car={car} moms={moms}/>
            }
          
        </div>
    )
}

export default Dashboard;
