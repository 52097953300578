import "./App.css";
import AppLayout from "./layout/AppLayout";
import "react-tooltip/dist/react-tooltip.css";
import { Toaster } from "react-hot-toast";

import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Users from "./pages/Users";
import Salary from "./pages/Salary";
import Register from "./pages/Register";
import Schedule from "./pages/Schedule";
import Start from "./pages/Start";
import User from "./pages/User";
import Shift from "./pages/Shift";
import Shifts from "./pages/Shifts";
import Signout from "./pages/Signout";
import NewShift from "./components/Shift/NewShift";
import Dashboard from "./pages/Dashboard";
import ExcelExport from "./pages/ExcelExport";

import { AuthProvider } from "./auth/AuthContext";

import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <AuthProvider>
      <Toaster />
      <Routes>
        <Route path="*" element={<Start />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signout" element={<Signout />} />
        <Route path="/app" element={<AppLayout />}>
          <Route path="start" element={<Start />} />
          <Route path="shifts" element={<Shifts />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="users" element={<Users />} />
          <Route path="user/:userId" element={<User />} />
          <Route path="salary" element={<Salary />} />
          <Route path="register" element={<Register />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="shift/:shiftId" element={<Shift />} />
          <Route path="shift/new" element={<NewShift />} />
          <Route path="excel" element={<ExcelExport />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
