import React from "react";
import {Text,DatePicker,SearchSelect, SearchSelectItem ,Title} from "@tremor/react";

import dayjs from "dayjs";

const RegisterHeader = ({
    setCompany,
    setDriver,
    setDate,
    company,
    companies,
    driver,
    drivers,
    date
}) => {
    
    const onChangeDate = (value) => {
        let date = dayjs(value);
        date = date.date(1);
        let newDate = ""
        newDate = date.format('YYYY-MM-DD');

        setDate(newDate)
    }

    return (
        <>
            <Title className="">Registera kontant</Title>
            <div className="flex flex-row ">
                <div className="mt-4 mb-2  mr-6">
                    <Text className="mb-2">Företag</Text>
                    <SearchSelect value={company} onValueChange={(value)=>setCompany(value)}>
                        {
                            companies.map((company,idx) => (
                                <SearchSelectItem key={idx} value={company.akareNo}>
                                    {company.haldaName}
                                </SearchSelectItem>
                            ))
                        }
                    </SearchSelect>
                </div>
                <div className="mt-4 mb-2  mr-6">
                    <Text className="mb-2">Månad</Text>
                    <DatePicker value={(!date) ? new Date() : new Date(date) } displayFormat="yyyy-MM-dd" className="max-w-sm mx-auto" onValueChange={onChangeDate}/>
                </div>
                <div className="mt-4 mb-2  mr-6">
                    <Text className="mb-2">Förare</Text>
                    <SearchSelect value={driver} onValueChange={(value)=>setDriver(value)}>
                        {
                            drivers.map((driver,idx) => (
                                <SearchSelectItem key={idx} value={driver.driverNo}>
                                    {driver.name} - {driver.driverNo}
                                </SearchSelectItem>
                            ))
                        }
                    </SearchSelect>
                </div>
               
            </div>
       </>
    );
}

export default RegisterHeader;