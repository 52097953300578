import React, {useEffect, useState} from "react";
import { PlusCircleIcon, SignalIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Link,useNavigate  } from "react-router-dom";
import {
    Badge,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    Title,
    Switch,
    TextInput,
    Divider,
    Grid,
    Col,
    Select,
    SelectItem,
    Button,
    Icon
} from "@tremor/react";

import { APP_ROUTES } from "../../config/routes";
import axios from "axios";

const UsersTable = ({users, removeUser,setSelectedUser}) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState(null);

    useEffect(() => {
        const getCompanies = async () => {
            try{
                const response = await axios.get('/company/all');
                if(response.status === 200)
                    setCompanies(response.data);
            }catch(err){
                setCompanies([]);
                console.log(err);
            }
        }

        getCompanies();
    },[]);

    useEffect(() => {
        setFilteredUsers(filterUsers());
    },[users]);


    const filterUsers = ()=>{
        let finalList = users;
        if(isSwitchOn){
            finalList = users.filter((user) => user.active === isSwitchOn)
        }

        if(company !== null){
            finalList = finalList.filter((user) => user.companies.includes(parseInt(company)))
        }

        if(searchText !== ""){
            finalList = finalList.filter((user) => {
                return  user.name?.toLowerCase().includes(searchText.toLowerCase()) ||
                        user.email?.toLowerCase().includes(searchText.toLowerCase())
            })
        }

        return finalList;
    };

    
    useEffect(() => {
        setFilteredUsers(filterUsers());
    },[searchText,isSwitchOn,company]);

    return (
        <div className="w-full mt-8" >
            <Card>
                <div className="flex flex-row justify-between mb-8">
                    <div className="w-full flex justify-end items-right">
                        <Button className="" icon={PlusCircleIcon} size="xs" onClick={()=>navigate(APP_ROUTES.SIGNUP)}>Lägg till användare</Button>
                    </div>
                </div>
                <div className="flex items-center space-x-3">
                    <Switch id="switch" name="switch" checked={isSwitchOn} onChange={setIsSwitchOn} />
                    <label htmlFor="switch" className="text-sm text-gray-500">
                     Aktiva användare
                    </label>
                </div>
                <Grid>
                    <Col>
                        <div className="mt-6 mb-6 w-2/5">
                            <label>Sök:</label>
                            <TextInput className="mt-4" name="searchText" onValueChange={setSearchText} />
                        </div>
                    </Col>
                    <Col>
                    <div className="mt-4 mb-2 w-1/5 mr-6">
                        <Text className="mb-2">Företag</Text>
                        <Select value={company || ""} onValueChange={(value)=>setCompany(value)}>
                            <SelectItem value={null}>
                                Alla
                            </SelectItem>
                            {(!companies) ? null :
                                companies.map((company,idx) => (
                                    <SelectItem key={idx} value={company.akareNo}>
                                        {company.haldaName}
                                    </SelectItem>
                                ))
                            }
                        </Select>
                    </div>
                    </Col>
                </Grid>
                <Divider>Lista</Divider>
                <Table className="mt-5">
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Namn</TableHeaderCell>
                        <TableHeaderCell>Email</TableHeaderCell>
                        <TableHeaderCell>Aktiv</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredUsers.map((item,idx) => (
                    <TableRow key={item.id+""+idx}>
                        <TableCell>
                            <Link to={`/app/user/${item.id}`}>{item.name}</Link>
                        </TableCell>
                        <TableCell>
                            <Text>{item.email}</Text>
                        </TableCell>
                        <TableCell>
                            <Badge color={(item.active) ? "emerald" : "red"} icon={SignalIcon}>
                                {(item.active) ? 'aktiv' : 'inaktiv'}
                            </Badge>
                        </TableCell>
                        <TableCell>
                            {
                                (item.rights === 1 || item.rights === 2) ? null :
                                <Icon className="h-6 w-6 text-red-300 cursor-pointer" size="sm" icon={TrashIcon} onClick={()=>setSelectedUser(item.id)} />
                            }
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </Card>
        </div>
    );
}

export default UsersTable;