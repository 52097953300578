import React from "react";
import {
    TableCell,
    TableRow,
    Text,
    Badge, BadgeDelta 
} from "@tremor/react";

import {convertToCurrency} from "../../lib/utils";

const SalaryTableRow = ({rowData,badge=null,style}) => {
    return (
        <TableRow className={(style) ? 'bg-slate-100' : ''}>
            <TableCell className="w-[60%]">
                <Text>{rowData['label']}</Text>
            </TableCell>
            <TableCell className="flex flex-row">
                <Text className={`${ (rowData['value'] < 0 ) ? 'text-red-400' : '' }`}>{
                    (rowData['type'] === "currency") ? convertToCurrency(rowData['value']) : rowData['value']
                }</Text>
                {
                    (badge) ? 
                    <Badge size="xs" color="yellow">{badge}</Badge>: null
                }
            </TableCell>
            <TableCell>
                <Text>{rowData['alternateValue']}</Text>
                
            </TableCell>
            {/* <TableCell>
                <Text className="text-right">{(rowData['type'] === "currency") ? convertToCurrency( rowData['approved'] ) : rowData['approved'] }</Text>
            </TableCell> */}
        </TableRow>
    )
}

export default SalaryTableRow;