import React , {useEffect, useState}from "react";
import {Select, SelectItem, Text , DatePicker,Title,Grid,Col,Button,TextInput,Textarea,Card, Divider} from '@tremor/react'
import axios from "axios";
import dayjs from "dayjs";
import { validateTime ,calculateTotalHours, formatTime} from "../../lib/utils";
import { useNavigate } from "react-router-dom";
import BreaksList from "./BreaksList";
import AddBreak from "./AddBreak";
import { useAuth } from "../../auth/AuthContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { v4 as uuidv4 } from 'uuid';

const NewShift = ({customShiftDriver,closeNewShift}) => {

    const {user} = useAuth();
    const [pass,setPass] = useState({
        starttimeUI : '',
        endtimeUI : '',
    })
    const [breaks,setBreaks] = useState([]) 

    const [company,setCompany] = useState(null)
    const [type,setType] = useState(null)
    const [companies,setCompanies] = useState([])
    const [date,setDate] = useState(null)
    const [hoursWorked,setHoursWorked] = useState(null)
    const [drivers,setDrivers] = useState([])
    const [driver,setDriver] = useState(null)

    const navigate = useNavigate();

    const onChange = (value,name) => {
        setPass({...pass,[name]:value})
    }

    const onChangeDate = (value,key = null) => {
        let date = dayjs(value);
        let newDate = ""
        newDate = date.format('YYYY-MM-DD');
        if(key)
            onChange(newDate,key);

        if(key === 'startdate' ){
            setDate(newDate);
        }
    }

    async function getCompanies(){
        if(!date)
            return;

        const response = await axios.get('/company/available?startdate='+date);
        setCompanies(response.data);
    }

    useEffect(() => {
        if(customShiftDriver){
            setDriver(customShiftDriver);
        }
    },[customShiftDriver]);

    useEffect(() => {
        async function getDrivers(){
            const response = await axios.get('/drivers?akareNo='+company+'&startdate='+'2024-01-01'); // Grab all drivers that have driven since the begining of 2024.
            setDrivers(response.data);
        }
        if(company && date)
            getDrivers();
    },[company,date]);

    useEffect(() => {
        getCompanies();
    },[date]);

    useEffect(() => {
        let total = 0;
        if((validateTime(pass.starttimeUI) && validateTime(pass.endtimeUI))){
            total = calculateTotalHours({report : {...pass}, breaks : breaks})
        }
        setHoursWorked(total)    
    },[pass,breaks]);

    const isDisabled = () => {
        if(!pass.companyId || !pass.enddate || !pass.startdate || !pass.starttimeUI || !pass.endtimeUI || !pass.report_type || !pass.activity || !validateTime(pass.starttimeUI) || !validateTime(pass.endtimeUI) || !validateTime(breaks.starttime) || !validateTime(breaks.endtime)){
            return true;
        }
        return false;
    }

    const createShift = async () => {
        try{
            if(!pass.companyId || !pass.enddate || !pass.startdate || !pass.starttimeUI || !pass.endtimeUI ){
                alert("Please fill in all fields");
                return;
            }
            // Add extra fields
            pass['hoursWorked'] = hoursWorked;
            pass['breaks'] = breaks;
            pass['driverNo'] = driver;

            const response = await axios.post(`/shifts`,pass);
            if(response.status !== 200) {
                throw Error(response.message);
            }
            if(customShiftDriver){
                closeNewShift();
            }else{
                navigate('/app/shifts')
            }
        }catch(err){
            console.log(err);
        }
    }

    const removeBreak = (tmpId) => {
        let newBreaks = breaks.filter((b,idx) => b.tmpId !== tmpId);
        setBreaks(newBreaks);
    }

    const addBreak = (starttime,endtime) => {
        if(!starttime || !endtime ) return;

        let newBreaks = [...breaks, {starttime : starttime, endtime : endtime, tmpId : uuidv4()}];
        setBreaks(newBreaks);
    }

    return (
        <div className="container mx-auto w-[80%] mt-6 ">
            <div className="pb-12">
                <Card className="pb-16">
                    <XMarkIcon className="w-6 h-6 text-gray-500 cursor-pointer mb-6 float-right" onClick={()=>{
                        if(customShiftDriver){closeNewShift()}else{window.history.back()}
                    }} />
                    <Title className="mb-6">Ny Rapport</Title>
                    
                    <Grid numItems={4} numItemsSm={4} numItemsMd={4} numItemsLg={4} className="gap-2">
                        <Col numColSpan={1} numColSpanMd={1} numColSpanLg={2}>
                            <div className="mt-4 mb-2 ">
                                <Text className="mb-2">Start Datum *</Text>
                                <DatePicker defaultValue={pass.startdate} placeholder="Väljdatum" displayFormat="yyyy-MM-dd" className="" onValueChange={(v)=>onChangeDate(v,'startdate')}/>
                            </div>
                        </Col>
                        <Col numColSpan={1} numColSpanMd={1} numColSpanLg={2}>
                            <div className="mt-4 mb-2 ">
                                <Text className="mb-2">Slut Datum *</Text>
                                <DatePicker defaultValue={pass.enddate} placeholder="Väljdatum" displayFormat="yyyy-MM-dd" className="" onValueChange={(v)=>onChangeDate(v,'enddate')}/>
                            </div>
                        </Col>

                        <Col numColSpan={2} numColSpanMd={2} >
                            <div className="mt-4 mb-2">
                                <Text className="mb-2">Företag *</Text>
                                <Select value={company} placeholder="Välj" onValueChange={(value)=>{setCompany(value);onChange(value,'companyId')}}>
                                    {
                                        companies.map((company,idx) => (
                                            <SelectItem key={idx} value={company.akareNo}>
                                                {company.haldaName}
                                            </SelectItem>
                                        ))
                                    }
                                </Select>
                                <p className="mt-2 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                                    Välj Företag
                                </p>
                            </div>
                        </Col>
                        {
                            (user.rights !== 5) ? 
                            <Col numColSpan={3} numColSpanMd={2} numColSpanSm={3} numColSpanLg={2}>
                                <div className="mt-4 mb-2 mr-6">
                                    <Text className="mb-2">Förare *</Text>
                                    <Select value={driver || ""} placeholder="Välj" onValueChange={(value)=>setDriver(value)}>
                                        {
                                            drivers.map((driver,idx) => (
                                                <SelectItem key={idx} value={driver.driverNo}>
                                                    {driver.name} - {driver.driverNo}
                                                </SelectItem>
                                            ))
                                        }
                                    </Select>
                                    <p className="mt-2 text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                                        Välj Förare
                                    </p>
                                </div>
                            </Col>
                            : null
                        }
                        



                        <Col numColSpan={2} numColSpanMd={4} numColSpanLg={2}>
                            <div className="mt-4 mb-2">
                                <Text className="mb-2">Typ: *</Text>
                                <Select value={type} placeholder="Välj" onValueChange={(value)=>{setType(value);onChange(value,'report_type')}}>
                                    <SelectItem value={1}>
                                        Kör
                                    </SelectItem>
                                    <SelectItem value={2}>
                                        Semester
                                    </SelectItem>
                                    <SelectItem value={3}>
                                        Sjuk
                                    </SelectItem>
                                    <SelectItem value={4}>
                                        Övrigt
                                    </SelectItem>
                                </Select>
                            </div>
                        </Col>
                        <Col numColSpan={2} numColSpanMd={4} numColSpanLg={2}>
                            <div className="mt-4 mb-2 mr-6">
                                <Text className="mb-2">Aktivitet *</Text>
                                <TextInput onValueChange={(value)=>onChange(value,'activity')}  placeholder="Ange"/>
                            </div>
                        </Col> 
                        
                        <Col numColSpan={2} numColSpanMd={4} numColSpanLg={2}>
                            <div className="mt-4 mb-2 mr-6">
                                <Text className="mb-2">Fordon</Text>
                                <TextInput maxLength="4" disabled={pass.report_type !== 1} type="number" placeholder="Bil nr" onValueChange={(value)=>onChange(value,'carNo')} />
                            </div>
                        </Col> 
                    
                        <Col numColSpan={4}>
                            <Divider>Tid</Divider>
                        </Col>
                        <Col numColSpan={1} numColSpanMd={2}>
                            <div className="mt-4 mb-2">
                                <Text className="mb-2">Start</Text>
                                <TextInput 
                                    placeholder="00:00"
                                    error={!validateTime(formatTime(pass.starttimeUI))} 
                                    errorMessage={"Var vänlig att ange tiden i formatet: TT:MM"} 
                                    value={formatTime(pass.starttimeUI)}
                                    onValueChange={(value)=>onChange(value,'starttimeUI')} />
                            </div>
                        </Col>
                        <Col numColSpan={1} numColSpanMd={2}>
                            <div className="mt-4 mb-2">
                                <Text className="mb-2">Slut</Text>
                                <TextInput 
                                    placeholder="00:00"
                                    error={!validateTime(formatTime(pass.endtimeUI))} 
                                    errorMessage={"Var vänlig att ange tiden i formatet: TT:MM"} 
                                    value={formatTime(pass.endtimeUI)}
                                    onValueChange={(value)=>onChange(value,'endtimeUI')} />
                            </div>
                        </Col>
                       

                        <Col numColSpan={4}>
                            <Divider>Rast</Divider>
                        </Col>
                        <BreaksList breaks={breaks} removeBreak={removeBreak}/>
                        <AddBreak addBreak={addBreak} />

                        <Col numColSpan={4}>
                            <div className="mt-4 mb-2 mr-6">
                                <Text className="mb-2">Arbetat tid </Text>
                                <Text>{hoursWorked}</Text>
                            </div>
                        </Col>

                        <Col numColSpan={4}>
                            <Divider>Reportering</Divider>
                        </Col>
                        <Col numColSpan={4} >
                            <Text>Avvikelser / Felrapportering</Text>
                            <div className="mt-4 mb-6">
                                <Text className="mb-2">Ekonomi/Lön/ADM</Text>
                                <Textarea placeholder="" value={pass.errorReportAdm} onChange={(e)=>onChange(e.target.value,'errorReportAdm')} />
                            </div>
                        </Col>

                        
                        <Col numColSpan={4} >
                            <Text className="">Felanmälan</Text>
                            <div className="mt-4 mb-6">
                                <Text className="mb-2">Fordon / Teknik / Tvätthall</Text>
                                <Textarea placeholder="" className="mb-6" value={pass.errorReportVehicle} onChange={(e)=>onChange(e.target.value,'errorReportVehicle')}  />
                            </div>
                        </Col>

                    </Grid>
                    <Button size="xs" className="float-right" disabled={isDisabled()} onClick={()=>createShift()}>Skicka in</Button>
                </Card>
            </div>
        </div>
    );
}

export default NewShift;
