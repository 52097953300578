import React,{useEffect,useState} from 'react';
import { useParams , useNavigate} from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import {Title, Card} from '@tremor/react';
import FullShiftReport from '../components/Shift/FullShiftReport';
import HalfShiftReport from '../components/Shift/HalfShiftReport';
import axios from 'axios';
import { XMarkIcon } from "@heroicons/react/24/outline";
import { calculateTotalHours } from '../lib/utils';

const Shift = ({selectedShiftId,closeInspectShift}) => {
    const {shiftId} = useParams();
    const {isAuthenticated} = useAuth()
    const navigate = useNavigate()
    const [shift, setShift] = useState({});

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/signin')
        }
    }   , [isAuthenticated,navigate])

    useEffect(() => {
        fetchShift();
    },[]);

    const unlockShift = async (id) => {
        try {
          const response = await axios.post(`/shifts/unlock/${id}`);
          if (response.status === 200) {
            if(selectedShiftId){closeInspectShift()}else{window.history.back()}
          }
        } catch (err) {
          console.log(err);
        }
      };

    const fetchShift = async () => {    
        try{
            const response = await axios.get(`/shifts/${(!shiftId ? selectedShiftId : shiftId)}`);
            const data = await response.data;
            if(response.status !== 200) {
                throw Error(response.message);
            }
            setShift(data);
        }catch(err){
            console.log(err);
        }
    }


    const updateShift = async (e) => {
        e.preventDefault();
        try{
            onChange(calculateTotalHours(shift), 'hoursWorkedUI')
            const response = await axios.put(`/shifts/${(!shiftId ? selectedShiftId : shiftId)}`,shift);
            await response.data;
            if(response.status !== 200) {
                throw Error(response.message);
            }

            //Cleanup / Reset
            onChange('newBreakStart' , "00:00")
            onChange('newBreakEnd',"00:00")

            fetchShift()
            if(selectedShiftId){closeInspectShift()}else{window.history.back()}
        }catch(err){
            console.log(err);
        }
    }

    const removeBreak = async (breakId) => {
        try{
            const response = await axios.delete(`/shifts/breaks/${breakId}`);
            await response.data;
            if(response.status !== 200) {
                throw Error(response.message);
            }
            fetchShift()
        }catch(err){
            console.log(err);
        }
    }


    const updateBreaks = async (value) => {
        let newShift = {...shift};
        newShift.breaks = value;
        setShift(newShift);
    }

    const removeShift = async () => {
        // only delete if manually created.
        try{
            const response = await axios.delete(`/shifts/${(!shiftId ? selectedShiftId : shiftId)}`);
            await response.data;
            if(response.status !== 200) {
                throw Error(response.message);
            }
            navigate('/app/shifts')
        }catch(err){
            console.log(err);
        }
    }

    const removeAttachment = async (type,id) => {
        try{
            const response = await axios.delete(`/shifts/attachments/${type}/${(!shiftId ? selectedShiftId : shiftId)}/${id}`);
            await response.data;
            if(response.status !== 200) {
                throw Error(response.message);
            }
            fetchShift()
        }catch(err){
            console.log(err);
        }
    }

    const createShift = async () => {
        try{
            onChange( calculateTotalHours(shift) , 'hoursWorkedUI')
            const response = await axios.post(`/shifts`,shift);
            const data = await response.data;
            if(response.status !== 200) {
                throw Error(response.message);
            }
            setShift(data);
        }catch(err){
            console.log(err);
        }
    }

    const onChange = (value,name) => {
        let newShift = {...shift};
        newShift.report[name] = value;
        setShift(newShift);
    }

    const changeAkare = async (akareNo) => {
        try{
            const response = await axios.post(`/shift/change/${(!shiftId ? selectedShiftId : shiftId)}`,{akareNo});
            await response.data;
            if(response.status !== 200) {
                throw Error(response.message);
            }
            window.history.back();
        }catch(err){
            console.log(err);
        }
    }


    if(!shift.report) return null;
    return (
        <div className="container mx-auto w-[80%] mt-6 ">
            <Card>
                <XMarkIcon className="w-6 h-6 text-gray-500 cursor-pointer mb-6 float-right" onClick={()=>{
                    if(selectedShiftId){closeInspectShift()}else{window.history.back()}
                }} />
                {/* <Title>Shift Details</Title> */}
                <Title className="mb-6">{`${shift.report.name} - Förare ${shift.report.driverNo}`}</Title>

                    {
                        (shift.report.workshiftNo === '0' || !shift.report.workshiftNo) ?
                        <HalfShiftReport shift={shift} 
                            onChange={onChange} 
                            updateShift={updateShift}
                            createShift={createShift}
                            removeShift={removeShift}
                            removeBreak={removeBreak}
                            removeAttachment={removeAttachment}
                            success={fetchShift}
                        /> : 
                        <FullShiftReport shift={shift} 
                            onChange={onChange} 
                            updateShift={updateShift}
                            createShift={createShift}
                            removeShift={removeShift}
                            removeBreak={removeBreak}
                            removeAttachment={removeAttachment}
                            success={onChange}
                            changeAkare={changeAkare}
                            updateBreaks={updateBreaks}
                            unlockShift={unlockShift}
                        /> 
                    }
            </Card>
        </div>
    );
}

export default Shift;