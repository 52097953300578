import React, { useEffect } from 'react';
import { useAuth } from '../auth/AuthContext'
import { useNavigate } from 'react-router-dom'

const Schedule = () => {
    const {isAuthenticated} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/signin')
        }
    }   , [isAuthenticated,navigate])
    return (
        <div>
            <h1>Schedule</h1>
        </div>
    );
}

export default Schedule;